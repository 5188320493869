import { useState } from 'react';
import { getYearDates } from '../../../utils/calendarDays';
import useHandleSessions from './useHandleSessions';

const useCalendar = ({ userData, userListType }) => {
  const initialDates = getYearDates(new Date());
  const [filterValue, setFilterValue] = useState(null);
  const userList = userData[userListType] ?? [];
  const learnerList = userData.learners ?? [];
  const [filteredUsers, setFilteredUsers] = useState(userList);

  const {
    data,
    upcomingSessions,
    error,
    success,
    confirmationVisible,
    setConfirmationVisible,
    handleGetSessions,
    setDates,
    dates,
    appointmentProps,
  } = useHandleSessions({ userData, initialDates });
  const filterCondition = (value) => (value ? ((session) => session?.learner?.id === value) : null);

  const onDateChange = ({ startDate, endDate }) => {
    setDates({ startDate, endDate });
    handleGetSessions({ startDate, endDate, filterCondition: filterCondition(filterValue) });
  };

  const onFilterChange = (value) => {
    setFilterValue(value);
    handleGetSessions({ ...dates, filterCondition: filterCondition(value) });
    const foundLearner = learnerList.find((learner) => learner.id === value);

    if (!foundLearner) {
      setFilteredUsers(userList);
    } else {
      setFilteredUsers(foundLearner.tutors ?? userList);
    }
  };

  return {
    data,
    upcomingSessions,
    error,
    success,
    confirmationVisible,
    setConfirmationVisible,
    onDateChange,
    onFilterChange,
    appointmentProps,
    dates,
    filteredUsers,
  };
};

export default useCalendar;
