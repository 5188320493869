import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Alert } from '@mui/material';

import { Button } from '~common/components';
import useStyles from './styles';
import DataGrid from '../../components/dataGrid';
import useManageUsers from './hooks/useManageUsers';

export const columns = [
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 100,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    width: 100,
  },
  {
    field: 'emailAddress',
    headerName: 'Email Address',
    width: 250,
  },
  {
    field: 'verified',
    headerName: 'Verified',
    width: 70,
  },
];

const AdminDashboard = () => {
  const { classes } = useStyles();

  const {
    data, getUsers, loading, error, setSelectedUsers, verifyUsers, isVerifyDisabled, success,
  } = useManageUsers();

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.headerBar}>
        <Typography
          component="h1"
          variant="h4"
          color="inherit"
          noWrap
          className={classes.heading}
        >
          Tutors
        </Typography>
        <Button
          className={classes.button}
          onClick={verifyUsers}
          disabled={isVerifyDisabled}
        >
          Verify Users
        </Button>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {error && <Alert severity="error" className={classes.alert}>{error}</Alert>}
            {success && <Alert severity="success" className={classes.alert}>{success}</Alert>}
            <DataGrid
              rows={data}
              columns={columns}
              getRowClassName={(params) => !params.row.verified && classes.unverifiedRow}
              loading={loading}
              getRowId={({ id }) => id}
              checkboxSelection
              isRowSelectable={(params) => !params.row.verified}
              onSelectionModelChange={(id) => setSelectedUsers(id)}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminDashboard;
