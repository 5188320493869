import { useState } from 'react';
import dayjs from 'dayjs';
import { config, dataLists } from '~common/utils';
import defaultSessionData from '../../../utils/sessionForm.json';
import { getLearnersList, getSubjectList } from '../utils/helpers';
import useSessionsStore, { sessionsSelector } from '../../../store/sessionsStore';

const { lessonDurationOptions } = dataLists;

const useHandleSessions = ({ userData, initialDates }) => {
  const { UserType } = config;
  const {
    learners,
    firstName,
    lastName,
    emailAddress,
    userType,
    userColorMap,
  } = userData;

  const [dates, setDates] = useState(initialDates);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [appointmentFormVisible, setAppointmentFormVisible] = useState(false);
  const [cancelledAppointmentId, setcancelledAppointmentId] = useState(undefined);
  const [editingAppointment, setEditingAppointment] = useState(undefined);
  const [addedAppointment, setAddedAppointment] = useState(undefined);
  const [previousAppointment, setPreviousAppointment] = useState(undefined);
  const [isNewAppointment, setIsNewAppointment] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const {
    sessions,
    upcomingSessions,
    getSessions,
    addSession,
    updateSession,
    cancelSession,
  } = useSessionsStore(sessionsSelector);

  const learnersList = getLearnersList(learners);
  const subjectList = getSubjectList(userData?.subjectList);

  const currentAppointment = sessions.filter(
    (appointment) => editingAppointment && appointment.id === editingAppointment.id,
  )[0] || addedAppointment;
  const appointmentData = currentAppointment
    ? { ...defaultSessionData, ...currentAppointment } : defaultSessionData;

  const handleGetSessions = async ({ startDate, endDate, filterCondition }) => {
    try {
      await getSessions(dayjs(startDate).valueOf(), dayjs(endDate).valueOf(), filterCondition);
    } catch (e) {
      setError('There has been an error retrieving your sessions');
    }
  };

  const handleAddSessions = async (addedSessionData) => {
    const {
      title, callDetails, meetingDetails, description, learner, subject, lessonDuration, freeTrial,
    } = addedSessionData;
    const endDate = lessonDuration.value
      ? dayjs(addedSessionData?.startDate).valueOf() + lessonDuration.value : null;

    await addSession({
      title,
      callDetails,
      meetingDetails,
      description,
      learners: [learner],
      tutors: [{
        id: userData?.id,
        firstName,
        lastName,
        emailAddress,
      }],
      subject,
      startDate: dayjs(addedSessionData?.startDate).valueOf(),
      endDate,
      freeTrial,
    });

    setIsNewAppointment(false);
  };

  const handleEditSession = async (editedSessionData) => {
    const {
      id,
      title,
      callDetails,
      meetingDetails,
      description,
      subject,
      lessonDuration,
      startDate,
      freeTrial,
    } = editedSessionData;
    const endDate = lessonDuration.value
      ? dayjs(startDate).valueOf() + lessonDuration.value : null;

    await updateSession({
      id,
      values: {
        title,
        startDate: dayjs(startDate).valueOf(),
        endDate,
        subject,
        description,
        callDetails,
        meetingDetails,
        freeTrial,
      },
    });

    // Refresh data.
    handleGetSessions(dates);
  };

  const commitCancelledAppointment = async (charge) => {
    setConfirmationVisible(false);
    try {
      await cancelSession(cancelledAppointmentId, charge);
      setSuccess('Session successfully cancelled.');
    } catch (e) {
      setError('There has been an error cancelling this session. Please try again or contact an administrator.');
    }
  };

  const commitChanges = async ({ added, cancelled, changed }) => {
    setSuccess(null);
    setError(null);

    if (added) {
      try {
        await handleAddSessions(added);
        setSuccess('Session created successfully.');
      } catch (e) {
        setError('There has been an error creating this session. Please try again or contact an administrator.');
      }
    } else if (changed) {
      try {
        await handleEditSession(changed);
        setSuccess('Session edited successfully.');
      } catch (e) {
        setError('There has been an error editing this session. Please try again or contact an administrator.');
      }
    } else if (cancelled) {
      setcancelledAppointmentId(cancelled);
      setConfirmationVisible(true);
    }
  };

  const onEditingAppointmentChange = (appointment) => {
    setEditingAppointment(appointment);
  };

  const onAddedAppointmentChange = (appointment) => {
    setAddedAppointment(appointment);
    if (editingAppointment !== undefined) {
      setPreviousAppointment(editingAppointment);
    }
    setEditingAppointment(undefined);
    setIsNewAppointment(true);
  };

  const cancelChanges = () => {
    setAppointmentFormVisible(false);
    // Cancel appointment
    if (isNewAppointment) {
      setEditingAppointment(previousAppointment);
      setIsNewAppointment(false);
    }
  };

  const formatSessions = (unformattedSessions) => unformattedSessions.map((session) => {
    const attendeeId = userType === UserType.TUTOR ? session.learner?.id : session.tutor?.id;

    return ({ ...session, color: userColorMap ? userColorMap?.[attendeeId] : null });
  });

  return {
    data: formatSessions(sessions),
    upcomingSessions: formatSessions(upcomingSessions),
    error,
    success,
    confirmationVisible,
    setConfirmationVisible,
    handleGetSessions,
    dates,
    setDates,
    appointmentProps: {
      appointmentData,
      commitChanges,
      cancelChanges,
      isNewAppointment,
      setAppointmentFormVisible,
      appointmentFormVisible,
      commitCancelledAppointment,
      onEditingAppointmentChange,
      onAddedAppointmentChange,
      learnersList,
      subjectList,
      lessonDurationOptions,
      userType: userType ?? '',
    },
  };
};

export default useHandleSessions;
