import {
  DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog,
} from '@mui/material';
import PropTypes from 'prop-types';

import { Button } from '~common/components';

const CancelDialog = ({
  confirmationVisible,
  setConfirmationVisible,
  commitCancelledAppointment,
}) => (
  <Dialog open={confirmationVisible} onClose={() => setConfirmationVisible(false)}>
    <DialogTitle>Cancelling Lesson</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to cancel this lesson?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => setConfirmationVisible(false)}
      >
        No, do not Cancel lesson
      </Button>
      <Button onClick={() => (commitCancelledAppointment(false))} variant="outlined">
        Yes, Cancel lesson - no charge
      </Button>
      <Button onClick={() => (commitCancelledAppointment(true))} variant="outlined">
        Yes, Cancel lesson - with charge
      </Button>
    </DialogActions>
  </Dialog>
);

CancelDialog.propTypes = {
  confirmationVisible: PropTypes.bool.isRequired,
  setConfirmationVisible: PropTypes.func.isRequired,
  commitCancelledAppointment: PropTypes.func.isRequired,
};

export default CancelDialog;
