import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Button, AvatarImage } from '~common/components';
import { config } from '~common/utils';

import useStyles from './styles';

const InfoCard = ({
  userType, session, setAppointmentFormVisible, onEditingAppointmentChange, showPastLessons,
}) => {
  const { classes } = useStyles();
  const { UserType } = config;
  const {
    subject, startDate, endDate, learner, tutor, title, callDetails,
  } = session;
  const isTutor = userType === UserType.TUTOR;
  const isParent = userType === UserType.PARENT;
  const attendeeName = isTutor ? learner?.fullName : tutor?.fullName;
  const avatar = isTutor ? learner?.id : tutor?.id;
  const buttonText = (!showPastLessons && isTutor) ? 'Edit' : 'View';
  const cardClasses = [classes.card];

  if (session.status === 'CANCELLED_WITH_CHARGE') {
    cardClasses.push(classes.cardCancelled);
  }

  return (
    <Grid container className={cardClasses.join(' ')}>
      <Grid item xs={12} md={4} className={classes.sessionDate}>
        <ListItemIcon>
          <CalendarTodayIcon className={classes.calendarIcon} />
          <div className={classes.dateTime}>
            <Typography className={classes.date} variant="body1">
              {dayjs(startDate).format('DD MMM, YYYY')}
            </Typography>
            <Typography className={classes.time} variant="body1">
              {dayjs(startDate).format('HH:mm')} - {dayjs(endDate).format('HH:mm')}
            </Typography>
          </div>
        </ListItemIcon>
        <Button
          onClick={() => {
            setAppointmentFormVisible(true);
            onEditingAppointmentChange(session);
          }}
          variant="text"
          className={classes.viewButton}
        >
          {buttonText}
        </Button>
      </Grid>
      <Grid item xs={12} md={4} className={classes.sessionInfo}>
        <div className={classes.sessionInfoContainer}>
          <Typography variant="body1" className={classes.subtitle}>{subject.subjectName}</Typography>
          <Typography variant="body2" className={classes.text}>{title}</Typography>
          <Typography variant="body2" className={classes.link}>
            <a target="_blank" rel="noreferrer" href={callDetails}>{callDetails}</a>
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={4} className={classes.attendeeInfoContainer}>
        {isParent && (
          <div className={classes.learnerInfo}>
            <AvatarImage
              className={classes.profileIcon}
              size="s"
              src={`${import.meta.env.VITE_AWS_AVATAR_URL}/${learner?.id}`}
            />
            <Typography variant="body2" className={classes.text}>{learner?.fullName}</Typography>
          </div>
        )}
        <div className={classes.attendeeInfo}>
          <AvatarImage
            className={classes.profileIcon}
            size="s"
            src={`${import.meta.env.VITE_AWS_AVATAR_URL}/${avatar}`}
          />
          <Typography variant="body2" className={classes.text}>{attendeeName}</Typography>
        </div>
      </Grid>
    </Grid>
  );
};

InfoCard.propTypes = {
  userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
  session: PropTypes.shape({
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    subject: PropTypes.shape({
      subjectName: PropTypes.string,
    }),
    learner: PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
    }),
    tutor: PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
    }),
    title: PropTypes.string,
    callDetails: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  setAppointmentFormVisible: PropTypes.func.isRequired,
  onEditingAppointmentChange: PropTypes.func.isRequired,
  showPastLessons: PropTypes.bool.isRequired,
};

export default InfoCard;
