import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingRight: 15,
  },
  title: {
    color: theme.palette.custom.primary1,
    fontWeight: 700,
    textTransform: 'capitalize',
    [theme.breakpoints.down('md')]: {
      color: theme.palette.custom.secondary1,
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,

    '& > :first-of-type': {
      marginRight: theme.spacing(1.5),
    },
  },
  text: {
    color: theme.palette.custom.primary1,
    [theme.breakpoints.down('md')]: {
      color: theme.palette.custom.secondary1,
    },
  },
  noDataText: {
    textAlign: 'center',
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 12,
  },
  filterTutors: {
    width: '100%',
    marginLeft: 20,
  },
}));

export default useStyles;
