const lessonsConfig = {
  admin: {
    canCreateLesson: true,
  },
  tutor: {
    canCreateLesson: true,
  },
  parent: {
    canCreateLesson: false,
  },
  learner: {
    canCreateLesson: false,
  },
};

export default lessonsConfig;
