import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: '17px 24px 0 24px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: 344,
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  title: {
    color: theme.palette.custom.primary1,
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  buttonContainer: {
    marginTop: '14px',
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#fff',
    paddingBottom: '17px',
  },
  link: {
    left: 10,
    color: theme.palette.custom.primary1,
    fontWeight: 500,
    fontSize: 14,
    textDecorationColor: theme.palette.custom.secondary1,
  },
}));

export default useStyles;
