import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    padding: 17,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    marginBottom: 18,
    marginLeft: 25,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      flexWrap: 'inherit',
    },
  },
  title: {
    color: theme.palette.custom.primary1,
    fontWeight: 700,
    marginBottom: 3,
  },
  dayTitle: {
    color: '#284B75',
    fontWeight: 600,
  },
  controlButtons: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    '& > button': {
      marginBottom: 0,
      [theme.breakpoints.down('md')]: {
        marginBottom: 8,
        width: '100%',
      },
    },
  },
  controlButtonsSticky: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    marginTop: theme.spacing(2),
    display: 'none',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      display: 'flex',
    },
    '& > button': {
      marginBottom: 0,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
  alert: {
    marginBottom: 8,
  },
  modal: {
    overflow: 'scroll',
  },
  modalContainer: {
    backgroundColor: '#FFFFFF',
    padding: 1,
    maxWidth: '700px',
    margin: '100px auto 0',
    borderRadius: 16,
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  toggleButtonHeader: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '&.MuiButton-root': {
      [theme.breakpoints.up('md')]: {
        marginRight: 12,
      },
    },
  },
  cardList: {
    width: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      marginBottom: '50px',
    },
  },
  noDataText: {
    textAlign: 'center',
    padding: 24,
    backgroundColor: '#FFFFFF',
  },
}));

export default useStyles;
