import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/Circle';
import useStyles from './styles';

dayjs.extend(AdvancedFormat);

const ContentComponent = ({
  appointmentData, userType,
}) => {
  const {
    startDate,
    endDate,
    subject,
    title,
    color,
    tutor,
    learner,
    callDetails,
  } = appointmentData;
  const { classes } = useStyles({ color });

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.nameRow}>
        <CircleIcon className={classes.dot} />
        <Typography
          className={classes.fullName}
          variant="body2"
          component="div"
        >
          {userType === 'tutor' ? learner.fullName : tutor.fullName}
        </Typography>
      </Grid>
      {userType === 'parent' && (
      <Grid item xs={12} className={classes.nameRow}>
        <Typography
          className={classes.fullName}
          variant="body2"
          component="div"
        >
          {learner.fullName}
        </Typography>
      </Grid>
      )}
      <Grid item xs={12}>
        <Typography className={classes.subject} variant="subtitle1" component="div">
          {subject.subjectName}
        </Typography>
        <Typography className={classes.title} variant="subtitle1" component="div">
          {title}
        </Typography>
        <Typography className={classes.link} variant="subtitle1" component="div">
          <a target="_blank" rel="noreferrer" href={callDetails}>{callDetails}</a>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.date} variant="subtitle1" component="div">
          {dayjs(startDate).format('dddd, Do MMMM')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.time} variant="subtitle1" component="div">
          {dayjs(startDate).format('hh:mm A')} - {dayjs(endDate).format('hh:mm A')}
        </Typography>
      </Grid>
    </Grid>
  );
};

ContentComponent.propTypes = {
  appointmentData: PropTypes.shape({
    title: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    id: PropTypes.string,
    subject: PropTypes.shape({
      subjectName: PropTypes.string.isRequired,
    }),
    learner: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }),
    tutor: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }),
    allDay: PropTypes.bool,
    callDetails: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.string.isRequired,
  }).isRequired,
  userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
};

export default ContentComponent;
