import PropTypes from 'prop-types';
import useStyles from './styles';

const TimeIndicator = ({ top, ...props }) => {
  const { classes } = useStyles({ top });

  return (
    <div
      id="time-indicator"
      {...props}
    >
      <div className={classes.timeIndicatorCircle} />
      <div className={classes.timeIndicatorline} />
    </div>
  );
};

TimeIndicator.defaultProps = {
  top: '0',
  ref: null,
};

TimeIndicator.propTypes = {
  top: PropTypes.string,
  ref: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

export default TimeIndicator;
