import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FileUploadStates } from '../fileUploadInput';
import { FILE_SIZE } from '../../../utils/validation/validationSchemas';

const contentTypeMap = {
  pdf: 'application/pdf',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  doc: 'application/msword',
  default: 'application/octet-stream',
};

const useHandleFileUpload = ({
  fileDescriptor, setValue, field, Storage,
}) => {
  const [status, setStatus] = useState(null);

  const handleFileUpload = async (e) => {
    const file = e?.target?.files[0];
    setStatus(FileUploadStates.loading);

    if (file) {
      if (file.size > FILE_SIZE) {
        setStatus(FileUploadStates.maxSizeLimit);
      } else {
        const fileId = uuid();
        const fileName = `${fileId}-${fileDescriptor}--${file.name}`;

        try {
          await Storage.put(`unverified/${fileName}`, file, {
            acl: 'public-read',
            cacheControl: 'no-cache',
            contentDisposition: `attachment; filename="${file.name}"`,
            contentType: contentTypeMap[file.name.split('.').pop() ?? contentTypeMap.default],
          });
          setStatus(FileUploadStates.success);
          setValue(field, fileName);
        } catch (err) {
          setStatus(FileUploadStates.error);
        }
      }
    }
  };
  return {
    handleFileUpload,
    status,
  };
};

export default useHandleFileUpload;
