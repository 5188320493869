import { WeekView, MonthView, DayView } from '@devexpress/dx-react-scheduler-material-ui';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

export const DayTimeTableCell = (props, readOnly, todaysDate) => {
  const { startDate } = props;

  // Disable the double click option if we don't want users to open the create session form.
  const canCreateSession = !readOnly && !dayjs(todaysDate).subtract(24, 'hours').startOf('hour').isAfter(dayjs(startDate).startOf('hour'));
  const formattedProps = canCreateSession ? { ...props } : { ...props, onDoubleClick: null };

  return <DayView.TimeTableCell {...formattedProps} />;
};

DayTimeTableCell.propTypes = {
  startDate: PropTypes.number.isRequired,
};

export const WeekViewTimeTableCell = (props, readOnly, todaysDate) => {
  const { startDate } = props;

  // Disable the double click option if we don't want users to open the create session form.
  const canCreateSession = !readOnly && !dayjs(todaysDate).subtract(24, 'hours').startOf('hour').isAfter(dayjs(startDate).startOf('hour'));
  const formattedProps = canCreateSession ? { ...props } : { ...props, onDoubleClick: null };

  return <WeekView.TimeTableCell {...formattedProps} />;
};

WeekViewTimeTableCell.propTypes = {
  startDate: PropTypes.number.isRequired,
};

export const MonthTimeTableCell = (props, readOnly, todaysDate) => {
  const { startDate } = props;

  // Disable the double click option if we don't want users to open the create session form.
  const canCreateSession = !readOnly && !dayjs(todaysDate).subtract(24, 'hours').startOf('day').isAfter(dayjs(startDate).startOf('day'));
  const formattedProps = canCreateSession ? { ...props } : { ...props, onDoubleClick: null };

  return <MonthView.TimeTableCell {...formattedProps} />;
};

MonthTimeTableCell.propTypes = {
  startDate: PropTypes.number.isRequired,
};
