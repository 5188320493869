import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  contentContainer: {
    padding: 17,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: 18,
    marginLeft: 25,
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  alert: {
    marginBottom: 8,
  },
}));

export default useStyles;
