import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Alert from '@mui/material/Alert';
import { Storage } from '@aws-amplify/storage';

import { Button, AvatarImage } from '~common/components';
import useStyles from './styles';
import useUserStore, { userSelector } from '../../../../store/userStore';

const contentTypeMap = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
  svg: 'image/svg+xml',
};

const uploadStatusMap = {
  info: { severity: 'info', message: 'Click browse to select an avatar' },
  success: { severity: 'success', message: 'Avatar uploaded' },
  error: { severity: 'error', message: 'Unable to load avatar, try again later' },
  max: { severity: 'error', message: 'Max file size reached' },
};

const Avatar = ({
  size,
}) => {
  const { classes } = useStyles({ size });
  const { updateUser, user } = useUserStore(userSelector);
  const [showUpload, setShowUpload] = useState(false);
  const [status, setStatus] = useState(uploadStatusMap.info);

  const handleUpload = useCallback(async (event) => {
    const file = event?.target?.files[0];

    if (file) {
      if (file.size > 5242880) {
        setStatus(uploadStatusMap.max);
      } else {
        try {
          await Storage.put(`avatars/${user.id}--${file.name}`, file, {
            bucket: import.meta.env.VITE_AWS_S3_PUBLIC_BUCKET_NAME,
            acl: 'public-read',
            cacheControl: 'no-cache',
            contentType: contentTypeMap[file.name.split('.').pop()],
          });
          await Storage.put(`avatars/${user.id}`, file, {
            bucket: import.meta.env.VITE_AWS_S3_PUBLIC_BUCKET_NAME,
            acl: 'public-read',
            cacheControl: 'no-cache',
            contentType: contentTypeMap[file.name.split('.').pop()],
          });
          setStatus(uploadStatusMap.success);

          const result = await updateUser({
            formData: {
              avatar: `${import.meta.env.VITE_AWS_AVATAR_URL}/${user.id}--${file.name}`,
            },
          });
          if (!result.isOk) {
            throw result.error;
          }
        } catch (err) {
          setStatus(uploadStatusMap.error);
          throw err;
        }
      }
    }
  });

  return (
    <Box className={classes.root}>
      <AvatarImage src={user.avatar} size={size} onClick={() => setShowUpload(!showUpload)} />
      <Dialog open={showUpload} onClose={() => setShowUpload(false)} maxWidth="sm">
        <DialogTitle className={classes.title}>
          Upload avatar
          <IconButton
            disableFocusRipple
            className={classes.titleIcon}
            onClick={() => {
              setShowUpload(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Avatar file must be no more than <strong>5MB</strong> and either a{' '}
            <strong>JPEG</strong>, <strong>JPG</strong>, <strong>PNG</strong>, or{' '}
            <strong>SVG</strong>.
          </DialogContentText>
          <br />
          <Alert severity={status.severity}>{status.message}</Alert>
          <br />
          <Button variant="contained" component="label" startIcon={<UploadFileIcon />}>
            Browse
            <input hidden onChange={handleUpload} type="file" accept=".png, .jpeg, .jpg, .svg" />
          </Button>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button
            variant="contained"
            onClick={() => {
              setStatus(uploadStatusMap.info);
              setShowUpload(false);
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

Avatar.defaultProps = {
  size: 'l',
};

Avatar.propTypes = {
  size: PropTypes.string,
};

export default Avatar;
