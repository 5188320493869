import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    overflow: 'auto',
  },
  contentContainer: {
    padding: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    marginBottom: 18,
    marginLeft: 25,
  },
  title: {
    color: theme.palette.custom.primary1,
    fontWeight: 700,
    marginBottom: 3,
  },
  dayTitle: {
    color: '#284B75',
    fontWeight: 600,
  },
}));

export default useStyles;
