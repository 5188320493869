import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  container: {
    padding: 4,
  },
  text: {
    fontSize: 12,
    color: '#FFFFFF',
    lineHeight: 1.25,
  },
}));
