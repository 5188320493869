const dashboardConfig = {
  admin: {
    canCreateLesson: true,
    canFilterTutors: true,
    userListType: 'learners',
  },
  tutor: {
    canCreateLesson: true,
    canFilterTutors: false,
    userListType: 'learners',
  },
  parent: {
    canCreateLesson: false,
    canFilterTutors: true,
    userListType: 'tutors',
  },
  learner: {
    canCreateLesson: false,
    canFilterTutors: false,
    userListType: 'tutors',
  },
};

export default dashboardConfig;
