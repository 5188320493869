import PropTypes from 'prop-types';
import {
  Divider,
  TextField,
  Autocomplete,
  Typography,
} from '@mui/material';
import useStyles from './styles';

const FilterByLearners = ({
  onFilterChange,
  learners,
  canFilterTutors,
}) => {
  const { classes } = useStyles();
  const options = [
    { id: '', value: 'All Learners' },
    ...(learners
      ? learners.map(({ id, firstName, lastName }) => ({
        id,
        value: `${firstName} ${lastName}`,
      }))
      : []),
  ];
  const numOfLearners = learners ? learners.length : 0;

  return (
    canFilterTutors && numOfLearners > 1 && (
      <>
        <Typography variant="body1" className={classes.title}>
          Filter By Learner
        </Typography>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.value}
          defaultValue={options[0]}
          className={classes.filterTutors}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, value) => onFilterChange(value?.id)}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        <Divider />
      </>
    )
  );
};

FilterByLearners.defaultProps = {
  canFilterTutors: false,
  learners: [],
};

FilterByLearners.propTypes = {
  canFilterTutors: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  learners: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
  })),
};

export default FilterByLearners;
