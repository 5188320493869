import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  title: {
    color: theme.palette.custom.primary1,
    fontWeight: 600,
    marginBottom: 8,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
    gap: 16,
  },
  header: {
    display: 'flex',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: -12,
  },
}));

export default useStyles;
