import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, FormControlLabel, Checkbox,
} from '@mui/material';

import {
  FormTextField,
} from '~common/components';
import useStyles from './styles';

const AccountDetailsFields = ({
  control,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { classes } = useStyles();

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={12}>
        <FormTextField
          name="emailAddress"
          control={control}
          inputProps={{
            label: 'Email Address',
          }}
          readOnly
        />
        <Typography variant="body2" className={classes.message}>
          Please note: If you need to update your email address please contact an administrator.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          name="currentPassword"
          control={control}
          inputProps={{
            label: 'Current Password',
            type: 'password',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="password"
          control={control}
          inputProps={{
            label: 'New Password',
            type: showPassword ? 'text' : 'password',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="confirmPassword"
          control={control}
          inputProps={{
            label: 'Confirm Password',
            type: showPassword ? 'text' : 'password',
          }}
        />
      </Grid>
      <Grid item={12}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
              className={classes.checkbox}
            />
            )}
          className={classes.label}
          label="Show Password"
        />
      </Grid>
    </Grid>
  );
};

AccountDetailsFields.propTypes = {
  control: PropTypes.shape({}).isRequired,
};

export default AccountDetailsFields;
