import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  overlay: {
    '&.Overlay-root': {
      position: 'fixed',
    },
  },
}));

export default useStyles;
