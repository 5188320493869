import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    padding: 17,
    flexDirection: 'column',
    overflow: 'auto',
  },
  header: {
    marginBottom: 18,
    marginLeft: 25,
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    color: theme.palette.custom.primary2,
    textDecoration: 'underline',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      color: theme.palette.custom.primary1,
    },
  },
}));

export default useStyles;
