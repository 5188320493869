import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  card: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    marginBottom: 10,
    padding: '16px 52px 16px 36px',
    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
  },
  cardCancelled: {
    textDecoration: 'line-through',
  },
  sessionDate: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  sessionInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      marginTop: 16,
    },
  },
  sessionInfoContainer: {
    width: '100%',
  },
  attendeeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down('md')]: {
      marginTop: 16,
      justifyContent: 'flex-start',
    },
  },
  attendeeInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: 'auto',
  },
  learnerInfo: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  profileIcon: {
    marginRight: 8,
  },
  subtitle: {
    fontWeight: 700,
    fontFamily: theme.typography.fontFamilyNunito,
    color: theme.palette.custom.primary1,
    marginBottom: 4,
  },
  text: {
    fontFamily: theme.typography.fontFamilyNunito,
    color: theme.palette.custom.primary1,
  },
  viewButton: {
    '&.MuiButton-root': {
      color: theme.palette.custom.primary1,
      padding: 0,
      textDecoration: 'underline',
      alignSelf: 'flex-start',
      marginLeft: 24,
      '&:hover': {
        color: theme.palette.custom.primary2,
        textDecoration: 'underline',
      },
    },
  },
  dayTitle: {
    color: '#284B75',
    fontWeight: 600,
  },
  calendarIcon: {
    color: theme.palette.custom.primary1,
    width: '20px',
    height: '20px',
    marginRight: 5,
  },
  date: {
    fontFamily: theme.typography.fontFamilyNunito,
    lineHeight: '21.82px',
    color: theme.palette.custom.primary1,
    fontWeight: 700,
  },
  time: {
    fontFamily: theme.typography.fontFamilyNunito,
    lineHeight: '19.1px',
    color: theme.palette.custom.primary1,
  },
  dateTime: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      '& > :first-child': {
        marginRight: 5,
      },
    },
  },
  link: {
    fontFamily: theme.typography.fontFamilyNunito,
    color: theme.palette.custom.primary1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
