import {
  Card, Grid, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import useStyles from './styles';

const ByMonthTitleBar = ({ isParentUserType }) => {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.summaryTitleBar}>
      <Grid item xs={3}>
        <Typography variant="body1" className={classes.cardTitle}>Date</Typography>
      </Grid>
      <Grid item xs={isParentUserType ? 7 : 5}>
        <Typography variant="body1" className={classes.cardTitle}>Description</Typography>
      </Grid>
      {!isParentUserType && (
      <Grid item xs={2}>
        <Typography variant="body1" className={classes.cardTitle}>Total Hours</Typography>
      </Grid>
      )}
      <Grid item xs={2}>
        <Typography variant="body1" className={classes.cardTitle}>Total Due</Typography>
      </Grid>
    </Grid>
  );
};

ByMonthTitleBar.propTypes = {
  isParentUserType: PropTypes.bool.isRequired,
};

const ByMonthCard = ({
  date, description, amount, hours, isParentUserType,
}) => {
  const { classes } = useStyles();
  // Convert pence to pounds.
  const totalDue = amount ? `£${amount / 100}` : '';
  const startDate = date ? dayjs(date).format('DD MMM, YYYY') : '';

  return (
    <Card className={classes.cardArea}>
      <Grid container>
        <Grid item xs={4} display={{ sm: 'none' }}>
          <Typography variant="body1" className={classes.cardTitle}>Date</Typography>
        </Grid>
        <Grid item xs={8} sm={3} className={classes.date}>
          {date
          && (
          <>
            <CalendarTodayIcon className={classes.calendarIcon} fontSize="small" />
            <Typography variant="body1" className={classes.cardText}>
              {startDate}
            </Typography>
          </>
          )}
        </Grid>
        <Grid item xs={4} display={{ sm: 'none' }}>
          <Typography variant="body1" className={classes.cardTitle}>Description</Typography>
        </Grid>
        <Grid item xs={8} sm={isParentUserType ? 7 : 5}>
          <Typography variant="body1" className={classes.description}>{description}</Typography>
        </Grid>
        {!isParentUserType && (
          <>
            <Grid item xs={4} display={{ sm: 'none' }}>
              <Typography variant="body1" className={classes.cardTitle}>Total Hours</Typography>
            </Grid>
            <Grid item xs={8} sm={2} className={classes.cardText}>
              <Typography variant="body1" className={classes.cardTitle}>{hours}</Typography>
            </Grid>
          </>
        )}
        <Grid item xs={4} display={{ sm: 'none' }}>
          <Typography variant="body1" className={classes.cardTitle}>Total Due</Typography>
        </Grid>
        <Grid item xs={8} sm={2} className={classes.cardText}>
          <Typography variant="body1" className={classes.cardTitle}>{totalDue}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

ByMonthCard.propTypes = {
  date: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  isParentUserType: PropTypes.bool.isRequired,
};

const PaymentsMonthly = ({ invoice, isParentUserType }) => {
  const { classes } = useStyles();

  if (!invoice) {
    return (
      <Card className={classes.cardArea}>
        <Typography variant="body1" className={classes.message}>No payment data.</Typography>
      </Card>
    );
  }

  const { total } = invoice;
  // Convert pence to pounds.
  const totalDue = total ? `£${total / 100}` : '';
  const items = invoice.lines ?? [];

  return (
    <div className={classes.cardList}>
      {items?.length
        ? (
          <> <ByMonthTitleBar isParentUserType={isParentUserType} />
            {items.map((item) => (
              <ByMonthCard
                date={item?.date}
                hours={item?.totalHoursTutored}
                description={item?.description}
                amount={item?.amount}
                key={item?.id}
                isParentUserType={isParentUserType}
              />
            ))}
            <Card className={classes.cardArea}>
              <Grid container>
                <Grid item xs={6} sm={8} className={classes.date} />
                <Grid item xs={4} sm={2}><Typography variant="body1" className={classes.cardTitle}>Total Due:</Typography></Grid>
                <Grid item xs={2} className={classes.statusText}>
                  <Typography variant="body1" className={classes.cardTitle}>{totalDue}</Typography>
                </Grid>
              </Grid>
            </Card>
          </>
        ) : (
          <Typography variant="body1" className={classes.noDataText}>
            No payment data.
          </Typography>
        )}
    </div>
  );
};

PaymentsMonthly.defaultProps = {
  invoice: null,
};

PaymentsMonthly.propTypes = {
  invoice: PropTypes.shape({
    total: PropTypes.number,
    lines: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        description: PropTypes.string,
        date: PropTypes.number,
        totalHoursTutored: PropTypes.number,
        id: PropTypes.string,
      }),
    ),
  }),
  isParentUserType: PropTypes.bool.isRequired,
};

export default PaymentsMonthly;
