import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Button, IconButton, Autocomplete, TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import dataLists from '../../../utils/dataLists';

const { subjects } = dataLists;

const SubjectsField = ({
  getValues,
  // eslint-disable-next-line react/prop-types
  control,
  setValue,
  singleExamBoardPerQualificationSubject,
  overwriteName,
}) => {
  const [qualificationLevels, setQualificationLevels] = useState([]);
  const updateFormValue = useCallback((levels) => {
    const flattenedData = levels.flatMap((level) => level.subjects.flatMap((subject) => {
      // eslint-disable-next-line no-use-before-define
      const isFree = isFreeQualificationLevel(level.qualificationLevel);
      if (isFree) {
        return [{
          qualificationLevel: {
            qualificationLevel: level.qualificationLevel.qualificationLevel,
            id: level.qualificationLevel.id,
          },
          subject: {
            subjectName: subject.subject?.subjectName,
            id: 999999,
          },
          examBoard: { examBoardName: '', id: 999999 },
          version: subject.version,
        }];
      }
      const examBoards = subject.examBoards.length > 0 ? subject.examBoards : [{ examBoardName: '', id: 999999 }];
      return examBoards.map((examBoard) => ({
        qualificationLevel: {
          qualificationLevel: level.qualificationLevel.qualificationLevel,
          id: level.qualificationLevel.id,
        },
        subject: {
          subjectName: subject.subject?.subjectName,
          id: subject.subject?.id,
        },
        examBoard: {
          examBoardName: examBoard !== null ? examBoard.boardName || examBoard.examBoardName : '',
          id: examBoard !== null ? examBoard.id : 0,
        },
        version: subject.version,
      }));
    }));
    setValue(overwriteName || 'subjectList', flattenedData);
  }, [setValue]);

  useEffect(() => {
    const formValues = getValues().subjectList || [];
    if ((formValues.length === 0) || (formValues.length === 1
      && formValues[0].qualificationLevel.id === undefined && formValues[0].subject.id === undefined)) {
      setQualificationLevels([{
        qualificationLevel: null,
        subjects: [{
          subject: null, changed: true, examBoards: [], version: 2,
        }],
      }]);
      return;
    }
    const groupedValues = formValues.reduce((acc, item) => {
      const qualIndex = acc.findIndex((q) => q.qualificationLevel.id === item.qualificationLevel.id);
      if (qualIndex === -1) {
        acc.push({
          qualificationLevel: item.qualificationLevel,
          changed: true,
          subjects: [{
            subject: item.subject,
            changed: true,
            examBoards: item.examBoard ? [item.examBoard] : [],
            version: item.version || 1,
          }],
        });
      } else {
        const subjectIndex = acc[qualIndex].subjects.findIndex((s) => s.subject.id === item.subject.id);
        if (subjectIndex === -1 || item.subject.id === 999999) {
          acc[qualIndex].subjects.push({
            subject: item.subject,
            changed: true,
            examBoards: item.examBoard ? [item.examBoard] : [],
            version: item.version || 1,
          });
        } else if (item.examBoard) {
          acc[qualIndex].subjects[subjectIndex].examBoards.push(item.examBoard);
        }
      }
      return acc;
    }, []);
    setQualificationLevels(groupedValues);
    console.log(groupedValues);
  }, [getValues]);

  const handleAddQualificationLevel = () => {
    setQualificationLevels([...qualificationLevels, {
      qualificationLevel: null,
      changed: false,
      subjects: [{
        subject: null, changed: false, examBoards: [], version: 2,
      }],
    }]);
    console.log(qualificationLevels, 'qualificationLevels');
  };

  const handleRemoveQualificationLevel = (index) => {
    const updatedLevels = qualificationLevels.filter((_, i) => i !== index);
    setQualificationLevels(updatedLevels);
    updateFormValue(updatedLevels);
  };

  const handleAddSubject = (qualIndex) => {
    const updatedLevels = [...qualificationLevels];
    updatedLevels[qualIndex].subjects.push({
      subject: null, changed: false, examBoards: [], version: 2,
    });
    setQualificationLevels(updatedLevels);
  };

  const handleRemoveSubject = (qualIndex, subjectIndex) => {
    const updatedLevels = [...qualificationLevels];
    updatedLevels[qualIndex].subjects.splice(subjectIndex, 1);
    setQualificationLevels(updatedLevels);
    updateFormValue(updatedLevels);
  };

  const handleChange = (qualIndex, subjectIndex, field, value) => {
    const updatedLevels = [...qualificationLevels];
    if (field === 'qualificationLevel') {
      updatedLevels[qualIndex].qualificationLevel = value;
      updatedLevels[qualIndex].changed = true;
      updatedLevels[qualIndex].subjects = [{
        subject: null, changed: false, examBoards: [], version: 2,
      }];
    } else if (field === 'subject') {
      // eslint-disable-next-line no-use-before-define
      updatedLevels[qualIndex].subjects[subjectIndex].subject = typeof value === 'string'
        ? { subjectName: value, id: 999999 }
        : value;
      updatedLevels[qualIndex].subjects[subjectIndex].changed = true;
      updatedLevels[qualIndex].subjects[subjectIndex].examBoards = [];
    } else if (field === 'examBoards') {
      updatedLevels[qualIndex].subjects[subjectIndex].examBoards = Array.isArray(value) ? value : [value];
      updatedLevels[qualIndex].subjects[subjectIndex].changed = true;
    }
    setQualificationLevels(updatedLevels);
    updateFormValue(updatedLevels);
    console.log(updatedLevels);
  };

  const isFreeQualificationLevel = (qualLevel) => subjects.find((q) => q.id === qualLevel?.id)?.free || false;

  const shouldShowExamBoard = (qualLevel) => {
    const qualLevelData = subjects.find((q) => q.id === qualLevel?.id);
    return qualLevelData && !qualLevelData.free && qualLevelData.examBoards.length > 0;
  };

  const renderSubjectField = (qualLevel, subjectItem, qualIndex, subjectIndex) => {
    const isFree = isFreeQualificationLevel(qualLevel.qualificationLevel);
    if (subjectItem.version === 1) {
      return (
        <Autocomplete
          value={subjectItem.subject}
          options={[]}
          getOptionLabel={(option) => option?.subjectName || ''}
          renderInput={(params) => <TextField {...params} label="Subject" />}
          readOnly
          disableClearable
        />
      );
    }

    return (
      <Autocomplete
        value={subjectItem.subject}
        control={control}
        options={isFree ? [] : (subjects.find((ql) => ql.id === qualLevel.qualificationLevel?.id)?.subjects || [])}
        getOptionLabel={(option) => option?.subjectName || ''}
        onChange={(_, value) => handleChange(qualIndex, subjectIndex, 'subject', value)}
        renderInput={
        (params) => (
          <TextField
            error={subjectItem.changed && !(subjectItem.subject
          && subjectItem.subject.subjectName)}
            onChange={isFree ? (e) => handleChange(qualIndex, subjectIndex, 'subject', e.target.value) : null}
            {...params}
            label="Subject *"
          />
        )
}
        freeSolo={isFree}
      />
    );
  };

  const renderExamBoardField = (qualLevel, subjectItem, qualIndex, subjectIndex) => {
    if (subjectItem.version === 1) {
      return (
        <Autocomplete
          value={subjectItem.examBoards[0]}
          options={[]}
          getOptionLabel={(option) => option?.boardName || option?.examBoardName || ''}
          renderInput={(params) => <TextField {...params} label="Exam Board" />}
          readOnly
          disableClearable
        />
      );
    }
    const examBoardOptions = subjects.find((ql) => ql.id === qualLevel.qualificationLevel?.id)?.examBoards || [];
    return (
      singleExamBoardPerQualificationSubject ? (
        <Autocomplete
          value={subjectItem.examBoards}
          options={examBoardOptions}
          control={control}
          getOptionLabel={(option) => option?.boardName || option?.examBoardName || ''}
          onChange={(_, value) => handleChange(qualIndex, subjectIndex, 'examBoards', value)}
          renderInput={(params) => (
            <TextField
              error={subjectItem.changed && !(subjectItem.examBoards && subjectItem.examBoards.length > 0)}
              {...params}
              label="Exam Board"
            />
          )}
          multiple
        />
      ) : (
        <Autocomplete
          value={subjectItem.examBoards[0]}
          options={examBoardOptions}
          control={control}
          getOptionLabel={(option) => option?.boardName || option?.examBoardName || ''}
          onChange={(_, value) => handleChange(qualIndex, subjectIndex, 'examBoards', [value])}
          renderInput={(params) => (
            <TextField
              error={subjectItem.changed && !(subjectItem.examBoards[0]
                && subjectItem.examBoards[0].id)}
              {...params}
              label="Exam Board"
            />
          )}
        />
      )
    );
  };

  return (
    <div style={{ paddingTop: 22 }}>
      {qualificationLevels.map((qualLevel, qualIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid container spacing={2} key={qualIndex} style={{ paddingBottom: 22 }}>
          <Grid item xs={11}>
            <Autocomplete
              value={qualLevel.qualificationLevel}
              options={subjects}
              control={control}
              getOptionLabel={(option) => option?.qualificationLevel || ''}
              onChange={(_, value) => handleChange(qualIndex, null, 'qualificationLevel', value)}
              renderInput={
              (params) => (
                <TextField
                  error={qualLevel.changed && !(qualLevel.qualificationLevel
                && qualLevel.qualificationLevel.qualificationLevel
                    && qualLevel.changed)}
                  {...params}
                  label="Qualification Level"
                />
              )
}
              readOnly={qualLevel.subjects.some((subject) => subject.version === 1)}
              disableClearable={qualLevel.subjects.some((subject) => subject.version === 1)}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => handleRemoveQualificationLevel(qualIndex)}>
              <ClearIcon />
            </IconButton>
          </Grid>
          {qualLevel.subjects.map((subjectItem, subjectIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={subjectIndex}>
              <Grid item xs={shouldShowExamBoard(qualLevel.qualificationLevel) || subjectItem.version === 1 ? 5 : 10}>
                {renderSubjectField(qualLevel, subjectItem, qualIndex, subjectIndex)}
              </Grid>
              {(shouldShowExamBoard(qualLevel.qualificationLevel) || subjectItem.version === 1) && (
                <Grid item xs={5}>
                  {renderExamBoardField(qualLevel, subjectItem, qualIndex, subjectIndex)}
                </Grid>
              )}
              {qualLevel.subjects.length > 1 && (
              <Grid item xs={1}>
                <IconButton onClick={() => handleRemoveSubject(qualIndex, subjectIndex)}>
                  <ClearIcon />
                </IconButton>
              </Grid>
              )}
            </React.Fragment>
          ))}
          {qualLevel.subjects.every((subject) => subject.version !== 1) && (
            <Grid item xs={12}>
              <Button
                onClick={() => handleAddSubject(qualIndex)}
                variant="outlined"
                endIcon={<AddIcon />}
              >
                Add another subject
              </Button>
            </Grid>
          )}
        </Grid>
      ))}
      <Grid
        item
        xs={12}
        style={{
          paddingTop: 20,
        }}
      >
        <Button
          onClick={handleAddQualificationLevel}
          variant="contained"
          endIcon={<AddIcon />}
        >
          Add another qualification level
        </Button>
      </Grid>
    </div>
  );
};

SubjectsField.propTypes = {
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  overwriteName: PropTypes.string,
  singleExamBoardPerQualificationSubject: PropTypes.bool,
};

SubjectsField.defaultProps = {
  singleExamBoardPerQualificationSubject: false,
  overwriteName: '',
};

export default SubjectsField;
