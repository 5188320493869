import { Typography, Container } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from './styles';

const WelcomeBanner = ({ firstName }) => {
  const { classes } = useStyles();

  return (
    <Container
      className={classes.container}
      sx={{
        display: { xs: 'none', md: 'block' },
        padding: { xs: 0, md: 0 },
        maxWidth: { xs: '100%', md: '100%' },
      }}
    >
      <div className={classes.box}>
        <div className={classes.textContainer}>
          <Typography
            component="h2"
            noWrap
            className={classes.title}
          >
            Welcome&nbsp;
            <Typography className={classes.nameTitle}>
              {firstName}
            </Typography>
          </Typography>
        </div>
        <div className={classes.image}>
          <img
            src="sketching.svg"
            alt="person-sketching"
          />
        </div>
      </div>
    </Container>
  );
};

WelcomeBanner.defaultProps = {
  firstName: '',
};

WelcomeBanner.propTypes = {
  firstName: PropTypes.string,
};

export default WelcomeBanner;
