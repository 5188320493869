import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { BasePageTemplate, Button } from '~common/components';
import { config } from '~common/utils';
import useStyles from './styles';
import dashboardConfig from '../../pages/dashboard/dashboardConfig';
import UserList from '../UserList';

const { UserType } = config;

export const getNavLinkItems = ({ userType, isMobileView }) => [
  {
    href: isMobileView ? '/dashboard' : '/', text: 'Dashboard', icon: '/calendar.svg', alt: 'dashboard-icon',
  },
  {
    href: '/lessons', text: 'All Lessons', icon: '/menu-board.svg', alt: 'lessons-icon',
  },
  {
    href: '/notifications', text: 'Notifications', icon: '/notification-bing.svg', alt: 'notifications-icon',
  },
  ...((userType === UserType.TUTOR
    || userType === UserType.PARENT
    || userType === UserType.ADMIN) ? [
      {
        href: '/payments', text: 'Payments', icon: '/project.svg', alt: 'payment-icon',
      },
    ] : []),
];

const UserListPanel = ({ classes, userData, handleMobileNavToggle }) => {
  const { userColorMap, userType } = userData;
  const { canCreateLesson, userListType } = dashboardConfig[userType] ?? {};
  const userList = userData[userListType] ?? [];
  const navigate = useNavigate();

  const handleOnClick = () => {
    handleMobileNavToggle();
    navigate('/lessons', { state: { createLesson: true } });
  };

  return (
    <div className={classes.userListContainer}>
      <UserList users={userList} userColorMap={userColorMap} userListType={userListType} />
      {canCreateLesson && (
        <div className={classes.lessonButtonContainer}>
          <Button fullWidth onClick={handleOnClick}>Create New Lesson</Button>
        </div>
      )}
    </div>
  );
};

UserListPanel.propTypes = {
  classes: PropTypes.shape({
    modal: PropTypes.string,
    modalContainer: PropTypes.string,
    lessonButtonContainer: PropTypes.string,
    userListContainer: PropTypes.string,
  }).isRequired,
  userData: PropTypes.shape({
    userColorMap: PropTypes.shape({}),
    userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
  }).isRequired,
  handleMobileNavToggle: PropTypes.func.isRequired,
};

const PageTemplate = ({
  children, handleSignOut, user, userData, isAssumedMode, isMobileView,
}) => {
  const { isAuthenticated, isVerified } = user;
  const { userType } = userData;
  const { classes } = useStyles();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const handleMobileNavToggle = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  if (!isAuthenticated || !isVerified) {
    return (
      children
    );
  }

  return (
    <BasePageTemplate
      userData={userData}
      navigationItems={getNavLinkItems({ userType, isMobileView })}
      classes={classes}
      onSignout={handleSignOut}
      isAssumedMode={isAssumedMode}
      userListPanel={(
        <UserListPanel
          classes={classes}
          userData={userData}
          handleMobileNavToggle={handleMobileNavToggle}
        />
      )}
      mobileNavOpen={mobileNavOpen}
      handleMobileNavToggle={handleMobileNavToggle}
    >
      {children}
    </BasePageTemplate>
  );
};

PageTemplate.defaultProps = {
  userData: {
    fullName: '',
  },
  isAssumedMode: false,
  isMobileView: false,
};

PageTemplate.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    isVerified: PropTypes.bool,
    groups: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  userData: PropTypes.shape({
    fullName: PropTypes.string,
    userType: PropTypes.string.isRequired,
  }),
  isAssumedMode: PropTypes.bool,
  isMobileView: PropTypes.bool,
};

export default PageTemplate;
