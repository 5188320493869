import { useState } from 'react';
import dayjs from 'dayjs';
import useHandleSessions from '../../../components/calendar/hooks/useHandleSessions';

const CANCELLED_STATUS = ['CANCELLED_NO_CHARGE'];

const useLessonData = ({ userData }) => {
  const [showPastLessons, setShowPastLessons] = useState(false);
  const todaysDate = dayjs().format();
  const futureDate = dayjs().add(100, 'year').format();
  const pastDate = dayjs().subtract(1, 'year').format();
  // We add 100 years or subtract 1 year to retrieve upcoming or past lessons.
  const futureLessonDates = { startDate: todaysDate, endDate: futureDate };
  const pastLessonDates = { startDate: pastDate, endDate: todaysDate };

  const {
    data,
    error,
    success,
    confirmationVisible,
    setConfirmationVisible,
    handleGetSessions,
    setDates,
    appointmentProps,
  } = useHandleSessions({ userData, initialDates: futureLessonDates });

  const filtered = data.filter((session) => !CANCELLED_STATUS.includes(session.status));
  const handleShowLessonsToggle = () => {
    const dates = showPastLessons ? futureLessonDates : pastLessonDates;
    setShowPastLessons(!showPastLessons);
    setDates(dates);
    handleGetSessions(dates);
  };

  return {
    data: filtered,
    error,
    success,
    confirmationVisible,
    setConfirmationVisible,
    handleGetSessions,
    futureLessonDates,
    handleShowLessonsToggle,
    showPastLessons,
    appointmentProps: {
      ...appointmentProps,
    },
  };
};

export default useLessonData;
