import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  filterTutors: {
    width: '100%',
    marginBottom: 12,
    backgroundColor: '#FFFFFF',
  },
  title: {
    color: theme.palette.custom.primary1,
    marginBottom: theme.spacing(1),
    fontWeight: 700,
  },
}));

export default useStyles;
