import PropTypes from 'prop-types';
import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';

import HeaderComponent from './components/headerComponent';
import ContentComponent from './components/contentComponent';

const LayoutComponent = ({
  appointmentMeta, userType, readOnly, ...restProps
}) => {
  if (!appointmentMeta) {
    return null;
  }
  const { data: appointmentData } = appointmentMeta;

  return (
    <AppointmentTooltip.Layout
      {...restProps}
      appointmentMeta={appointmentMeta}
      appointmentData={appointmentData}
      headerComponent={(props) => HeaderComponent({
        ...props, readOnly, userType, appointmentData,
      })}
      contentComponent={(props) => ContentComponent({
        ...props, userType, appointmentData,
      })}
    />
  );
};

LayoutComponent.propTypes = {
  appointmentMeta: PropTypes.shape({
    target: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({}).isRequired,
  }).isRequired,
  userType: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default LayoutComponent;
