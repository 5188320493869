import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Avatar from './components/avatar';
import useStyles from './styles';

const ProfileUser = ({
  userType, fullName, size,
}) => {
  const { classes } = useStyles({ size });

  return (
    <div className={classes.profileContainer}>
      <Avatar size={size} />
      <Typography variant="body1" className={classes.title}>
        {fullName}
      </Typography>
      <Typography variant="body2" className={classes.text}>
        {userType}
      </Typography>
    </div>
  );
};

ProfileUser.defaultProps = {
  userType: '',
  fullName: '',
  size: 'small',
};

ProfileUser.propTypes = {
  userType: PropTypes.string,
  fullName: PropTypes.string,
  size: PropTypes.string,
};

export default ProfileUser;
