import PropTypes from 'prop-types';
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';

const DayScaleCell = (props) => {
  const formattedProps = { ...props };

  return <WeekView.DayScaleCell {...formattedProps} />;
};

DayScaleCell.propTypes = {
  today: PropTypes.bool.isRequired,
  startDate: PropTypes.shape({
    getDay: PropTypes.func,
  }).isRequired,
};

export default DayScaleCell;
