import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Close from '@mui/icons-material/Close';
import {
  Typography,
  Grid,
  IconButton,
  Modal,
} from '@mui/material';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormTextField,
  Button,
  FormDatePicker,
  FormTimePicker,
  FormAutocomplete,
  FormCheckbox,
} from '~common/components';
import { validationSchemas } from '~common/utils';

import useStyles from './styles';
import {
  getFormTitle, isEditable, getDefaultLessonDuration, isFutureLesson,
} from '../../utils/helpers';

const LessonForm = ({ appointmentProps, readOnly }) => {
  const { classes } = useStyles();
  const [freeTrialModalVisible, setFreeTrialModalVisible] = useState(false);
  const [formData, setFormData] = useState(null);

  // eslint-disable-next-line react/destructuring-assignment
  const {
    commitChanges,
    setAppointmentFormVisible,
    isNewAppointment,
    cancelChanges,
    learnersList,
    subjectList,
    lessonDurationOptions,
  } = appointmentProps;
  const appointmentData = {
    ...appointmentProps.appointmentData,
    startDate: appointmentProps.appointmentData.startDate
      ? dayjs(appointmentProps.appointmentData?.startDate).$d : dayjs().add(1, 'hour').startOf('hour'),
  };

  const { status } = appointmentData;

  const defaultValues = {
    ...appointmentData,
    startDate: appointmentData.startDate ?? dayjs(),
    lessonDuration: getDefaultLessonDuration(appointmentData, lessonDurationOptions),
  };

  const {
    handleSubmit, control, getValues, formState: { isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchemas.sessionFormSchema),
  });

  const editType = isNewAppointment ? 'added' : 'changed';

  const { startDate } = defaultValues;
  const isCancelled = ['CANCELLED_NO_CHARGE', 'CANCELLED_WITH_CHARGE'].includes(status);
  const canEditLesson = !isCancelled && isEditable({ startDate, readOnly, isNewAppointment });
  const isLessonScheduled = isFutureLesson(startDate);
  const showCancelButton = !isCancelled && !isNewAppointment && canEditLesson;

  // Once a session has been created you should not be able to assign it to a different learner.
  const canEditLearner = isNewAppointment && canEditLesson;

  const commitAppointment = (type, data) => {
    if (type === 'cancelled') {
      commitChanges({ [type]: data.id });
    } else if (type === 'changed') {
      commitChanges({ [type]: data });
    } else {
      commitChanges({ [type]: data });
    }

    setAppointmentFormVisible(false);
  };

  const preSubmit = (data) => {
    if (data.freeTrial) {
      setFormData(data);
      setFreeTrialModalVisible(true);
    } else {
      return commitAppointment(editType, data);
    }
  };

  const handleConfirmFreeTrial = () => commitAppointment(editType, formData);

  return (
    <div className={classes.formContainer}>
      <div className={classes.header}>
        <IconButton
          className={classes.closeButton}
          onClick={cancelChanges}
          size="large"
        >
          <Close color="action" />
        </IconButton>
      </div>
      <Typography component="h1" className={classes.title}>
        {getFormTitle({
          startDate, readOnly, isNewAppointment, status,
        })}
      </Typography>
      <form
        onSubmit={handleSubmit((data) => preSubmit(data))}
        className={classes.formPanel}
      >
        <Grid container columnSpacing={1}>
          <Grid item xs={12}>
            <FormTextField
              name="title"
              control={control}
              inputProps={{
                label: 'Title',
                autoFocus: true,
              }}
              readOnly={!canEditLesson}
            />
          </Grid>
          <Grid item xs={6} sm={4} className={classes.date}>
            <FormDatePicker
              name="startDate"
              control={control}
              readOnly={!canEditLesson || !isLessonScheduled}
              inputProps={{
                label: 'Start Date',
                minDate: dayjs(),
                disableIgnoringDatePartForTimeValidation: true,
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4} className={classes.date}>
            <FormTimePicker
              name="startDate"
              control={control}
              readOnly={!canEditLesson}
              inputProps={{
                label: 'Start time',
                minutesStep: 15,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.date}>
            <FormAutocomplete
              name="lessonDuration"
              defaultValue={getValues('lessonDuration')}
              control={control}
              options={lessonDurationOptions}
              getOptionLabel={(option) => option?.name}
              inputProps={{ label: 'Lesson Duration' }}
              readOnly={!canEditLesson}
            />
          </Grid>
          <Grid item xs={12} className={classes.autocomplete}>
            <FormAutocomplete
              name="subject"
              defaultValue={getValues('subject')}
              control={control}
              options={subjectList}
              getOptionLabel={(option) => option?.subjectName}
              inputProps={{ label: 'Subject' }}
              readOnly={!canEditLesson}
            />
          </Grid>
          <Grid item xs={12} className={classes.autocomplete}>
            <FormAutocomplete
              name="learner"
              defaultValue={getValues('learner')}
              control={control}
              options={learnersList}
              getOptionLabel={(option) => option?.fullName}
              inputProps={{ label: 'Student' }}
              disabled={!canEditLearner}
            />
          </Grid>
          <Grid item xs={12} className={classes.autocomplete}>
            <FormCheckbox
              name="freeTrial"
              control={control}
              label="Free trial lesson"
              disabled={!canEditLesson}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name="description"
              control={control}
              inputProps={{ label: 'Lesson Description and Feedback', multiline: true, rows: 3 }}
              readOnly={!canEditLesson}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name="callDetails"
              control={control}
              inputProps={{ label: 'Online Meeting Link (paste link ONLY)', multiline: true, rows: 3 }}
              readOnly={!canEditLesson}
            />
          </Grid>
          {status !== 'CANCELLED' && (
          <Grid item xs={12} className={classes.actionButtons}>
            {showCancelButton
              && (
                <Button
                  onClick={() => (commitAppointment('cancelled', { id: appointmentData.id }))}
                  type="button"
                  variant="outlined"
                >
                  Cancel Lesson
                </Button>
              )}
            {canEditLesson && <Button className={classes.actionButton} disabled={!isDirty} type="submit">Submit</Button>}
          </Grid>
          )}
        </Grid>
        <Modal
          open={freeTrialModalVisible}
          onClose={() => setFreeTrialModalVisible(false)}
          className={classes.modal}
        >
          <div className={classes.modalContainer}>
            Are you sure you want to submit this lesson as a Free Trial?
            <div className={classes.modalActionButtons}>
              <Button type="button" variant="text" onClick={() => setFreeTrialModalVisible(false)}>No, cancel</Button>
              <Button type="button" onClick={handleConfirmFreeTrial}>Yes, save</Button>
            </div>
          </div>
        </Modal>
      </form>
    </div>
  );
};

LessonForm.defaultProps = {
  readOnly: false,
  appointmentProps: {
    appointmentData: {
      title: '',
      startDate: null,
      endDate: null,
      id: '',
      subject: {},
      learners: {},
      allDay: false,
      callDetails: '',
      description: '',
      lessonDuration: null,
      freeTrial: false,
    },
    learnersList: [],
    subjectList: [],
    lessonDurationOptions: [],
  },
};

LessonForm.propTypes = {
  readOnly: PropTypes.bool,
  appointmentProps: PropTypes.shape({
    appointmentData: PropTypes.shape({
      title: PropTypes.string,
      startDate: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.instanceOf(Date),
      ]),
      endDate: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.instanceOf(Date),
      ]),
      id: PropTypes.string,
      subject: PropTypes.shape({}),
      learners: PropTypes.shape({}),
      allDay: PropTypes.bool,
      callDetails: PropTypes.string,
      description: PropTypes.string,
      lessonDuration: PropTypes.shape({}),
      status: PropTypes.string.isRequired,
      freeTrial: PropTypes.bool,
    }),
    learnersList: PropTypes.arrayOf(PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      id: PropTypes.string,
      emailAddress: PropTypes.string,
      fullName: PropTypes.string,
    })),
    subjectList: PropTypes.arrayOf(PropTypes.shape({
      subjectName: PropTypes.string,
      id: PropTypes.number,
    })),
    lessonDurationOptions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })),
    commitChanges: PropTypes.func,
    setAppointmentFormVisible: PropTypes.func,
    isNewAppointment: PropTypes.bool,
    cancelChanges: PropTypes.func,
  }),
};

export default LessonForm;
