import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import useStyles from './styles';
import Calendar from '../../components/calendar';
import useCalendar from '../../components/calendar/hooks/useCalendar';
import WelcomeBanner from './components/welcomeBanner';
import ProfileBar from './components/profileBar';
import dashboardConfig from './dashboardConfig';
import CalendarFeeds from '../../components/calendarFeed';
import PageTitle from '../../components/pageTitle';
import FilterByLearners from '../../components/filterByLearners';

const Dashboard = ({ userData, isMobileView }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobileViewForCalendar = useMediaQuery(theme.breakpoints.down('lg'));
  const container = document.getElementById('main-container');
  const {
    firstName, fullName, userType, userColorMap, id, learners,
  } = userData;
  const { canCreateLesson, userListType, canFilterTutors } = dashboardConfig[userType] ?? {};
  const {
    data,
    upcomingSessions,
    error,
    success,
    confirmationVisible,
    appointmentProps,
    setConfirmationVisible,
    onDateChange,
    onFilterChange,
    dates,
    filteredUsers,
  } = useCalendar({ userData, userListType });

  // Set the correct height whenever the window is resized on mobile views.
  useEffect(() => {
    const handleResize = () => {
      const appBarHeight = 64;
      container.style.height = `${window.innerHeight - appBarHeight}px`;
    };

    if (container && isMobileViewForCalendar) {
      window.addEventListener('resize', handleResize);
      handleResize();
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [container]);

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.header}>
          <PageTitle title="Dashboard" />
        </div>
        <WelcomeBanner firstName={firstName} />
        {error && <Alert severity="error" className={classes.alert}>{error}</Alert>}
        {success && <Alert severity="success" className={classes.alert}>{success}</Alert>}
        {isMobileView && (
          <FilterByLearners
            onFilterChange={onFilterChange}
            learners={learners}
            canFilterTutors={canFilterTutors}
          />
        )}
        <Calendar
          data={data}
          readOnly={!canCreateLesson}
          confirmationVisible={confirmationVisible}
          appointmentProps={appointmentProps}
          setConfirmationVisible={setConfirmationVisible}
          onDateChange={onDateChange}
          dates={dates}
          isMobileView={isMobileViewForCalendar}
        />
        <CalendarFeeds user={userData} />
      </div>
      <ProfileBar
        appointmentProps={appointmentProps}
        userType={userType}
        fullName={fullName}
        canCreateLesson={canCreateLesson}
        userListType={userListType}
        data={data}
        upcomingSessions={upcomingSessions}
        userColorMap={userColorMap}
        userId={id}
        onFilterChange={onFilterChange}
        learners={learners}
        canFilterTutors={canFilterTutors}
        users={filteredUsers}
      />
    </div>
  );
};

Dashboard.defaultProps = {
  userData: {
    firstName: '',
    fullName: '',
    userType: '',
    userColorMap: null,
  },
};

Dashboard.propTypes = {
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
    userColorMap: PropTypes.shape({}),
    id: PropTypes.string.isRequired,
    learners: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
    })),
  }),
  isMobileView: PropTypes.bool.isRequired,
};

export default Dashboard;
