import { useNavigate } from 'react-router-dom';
import { Divider, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Button } from '~common/components';
import useStyles from './styles';
import UpcomingSessions from '../upcomingSessions';
import ProfileUser from '../../../../components/profileUser';
import CreateLessonButton from '../../../../components/calendar/components/createLessonButton';
import UserList from '../../../../components/UserList';
import FilterByLearners from '../../../../components/filterByLearners';

const ProfileBar = ({
  appointmentProps,
  userType,
  fullName,
  canCreateLesson,
  users,
  userListType,
  upcomingSessions,
  userColorMap,
  onFilterChange,
  learners,
  canFilterTutors,
}) => {
  const { classes } = useStyles();
  const {
    onEditingAppointmentChange,
    onAddedAppointmentChange,
    setAppointmentFormVisible,
  } = appointmentProps;
  const navigate = useNavigate();

  return (
    <Paper sx={{ display: { xs: 'none', md: 'block' } }}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="body1" className={classes.title}>
            Profile
          </Typography>
          <Button
            className={classes.link}
            variant="text"
            onClick={() => navigate('/profile')}
          >
            Edit
          </Button>
        </div>
        <ProfileUser userType={userType} fullName={fullName} size="l" />
        <FilterByLearners
          onFilterChange={onFilterChange}
          learners={learners}
          canFilterTutors={canFilterTutors}
        />
        <UpcomingSessions
          data={upcomingSessions}
          setAppointmentFormVisible={setAppointmentFormVisible}
          onEditingAppointmentChange={onEditingAppointmentChange}
          userType={userType}
        />
        <Divider />
        <UserList
          userColorMap={userColorMap}
          userListType={userListType}
          users={users}
        />
        {canCreateLesson && (
        <div className={classes.buttonContainer}>
          <CreateLessonButton
            setAppointmentFormVisible={setAppointmentFormVisible}
            onEditingAppointmentChange={onEditingAppointmentChange}
            onAddedAppointmentChange={onAddedAppointmentChange}
            fullWidth
          />
        </div>
        )}
      </div>
    </Paper>
  );
};

ProfileBar.defaultProps = {
  users: [],
  upcomingSessions: [],
  userType: '',
  fullName: '',
  userListType: '',
  canCreateLesson: false,
  userColorMap: null,
  canFilterTutors: false,
  learners: [],
};

ProfileBar.propTypes = {
  appointmentProps: PropTypes.shape({
    onEditingAppointmentChange: PropTypes.func.isRequired,
    onAddedAppointmentChange: PropTypes.func.isRequired,
    setAppointmentFormVisible: PropTypes.func.isRequired,
  }).isRequired,
  userType: PropTypes.string,
  fullName: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  userListType: PropTypes.string,
  canCreateLesson: PropTypes.bool,
  upcomingSessions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    learner: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  })),
  userColorMap: PropTypes.shape({}),
  onFilterChange: PropTypes.func.isRequired,
  canFilterTutors: PropTypes.bool,
  learners: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    lastName: PropTypes.string,
    fullname: PropTypes.string,
  })),
};

export default ProfileBar;
