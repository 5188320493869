import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import useSessionStore, { sessionsSelector } from '../../../store/sessionsStore';

const uri = import.meta.env.VITE_AWS_CALENDAR_FEED_URL;

const useCalendarFeeds = ({ user }) => {
  const { sessions } = useSessionStore(sessionsSelector);
  const [calendarFeeds, setCalendarFeeds] = useState([]);
  const [copyResult, setCopyResult] = useState('');

  useEffect(() => {
    let feeds;

    if (sessions?.length) {
      feeds = user.userType === 'parent'
        ? user.learners?.map((learner) => ({
          key: learner?.id,
          label: `${learner.firstName}' calendar feed`,
          calendarUrl: `${uri}/${learner.id}.ics?cb=${uuid()}`,
        }))
        : [{
          key: user.id,
          label: 'Your calendar feed',
          calendarUrl: `${uri}/${user.id}.ics?cb=${uuid()}`,
        }];
      setCalendarFeeds(feeds);
    }
  }, [sessions, user]);

  return {
    copyResult,
    setCopyResult,
    calendarFeeds,
  };
};

export default useCalendarFeeds;
