import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import useStyles from './styles';

const ValueRow = ({ value }) => {
  const { classes } = useStyles();

  return <Typography variant="body1" className={classes.itemValue}>{value}</Typography>;
};

ValueRow.defaultProps = {
  value: '',
};

ValueRow.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

const renderValue = ({ value }) => {
  if (Array.isArray(value)) {
    // eslint-disable-next-line react/no-array-index-key
    return value.map((row, i) => <ValueRow value={row} key={i} />);
  }

  return <ValueRow value={value} />;
};

const ProfileItem = ({ title, value, onEditItem }) => {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.item}>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1" className={classes.itemTitle}>{title}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.valueContainer}>
        {value && renderValue({ value })}
        {onEditItem && (
          <IconButton
            className={classes.editIcon}
            onClick={onEditItem}
          >
            <EditIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

ProfileItem.defaultProps = {
  value: '',
  onEditItem: null,
};

ProfileItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.node,
  ]),
  onEditItem: PropTypes.func,
};

export default ProfileItem;
