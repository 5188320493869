import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, props) => ({
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  profileIcon: {
    width: props.size === 'large' ? 180 : 140,
    height: props.size === 'large' ? 180 : 140,
    color: theme.palette.custom.secondary3,
    borderRadius: '50%',
    boxShadow: '0px 10px 50px #B6B9C7',
    marginBottom: 18,
  },
  text: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.custom.primary3,
    textTransform: 'capitalize',
  },
  title: {
    color: theme.palette.custom.primary1,
    fontWeight: 700,
    fontSize: props.size === 'large' ? 24 : 16,
  },
}));

export default useStyles;
