import PropTypes from 'prop-types';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import * as Sentry from '@sentry/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { Error, NotFound } from '~common/components';
import { useAuth } from '~common/utils';
import PageTemplate from '../components/pageTemplate';
import Dashboard from '../pages/dashboard';
import PrivateRoute from './PrivateRoute';
import AdminDashboard from '../pages/adminDashboard';
import useUser from './hooks/useUser';
import Lessons from '../pages/lessons';
import Notifications from '../pages/notifications';
import Profile from '../pages/profile';
import Payments from '../pages/payments';
import ScrollToTop from '../utils/ScrollToTop';

function App({ assumedUserId }) {
  const { handleSignOut, user } = useAuth({ Hub, Auth, bypassCache: false });
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { userData, isAssumedMode, loading } = useUser({ assumedUserId });

  const routes = (
    <Router>
      {!loading && (
        <PageTemplate
          handleSignOut={handleSignOut}
          user={user}
          userData={userData}
          isAssumedMode={isAssumedMode}
          isMobileView={isMobileView}
        >
          <ScrollToTop />
          <Routes>
            <>
              <Route
                index
                path="/"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut} redirect={isMobileView ? '/lessons' : ''}>
                    {isMobileView
                      ? <Lessons userData={userData} />
                      : <Dashboard userData={userData} isMobileView={isMobileView} />}
                  </PrivateRoute>
                )}
                errorElement={<NotFound />}
              />
              <Route
                path="/dashboard"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <Dashboard userData={userData} isMobileView={isMobileView} />
                  </PrivateRoute>
              )}
              />
              <Route
                path="/lessons"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <Lessons userData={userData} />
                  </PrivateRoute>
              )}
              />
              <Route
                path="/notifications"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <Notifications userData={userData} />
                  </PrivateRoute>
              )}
              />
              <Route
                path="/payments"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <Payments userData={userData} />
                  </PrivateRoute>
              )}
              />
              <Route
                path="/payments/:paymentMonthId"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <Payments userData={userData} />
                  </PrivateRoute>
              )}
              />
              <Route
                path="/profile"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <Profile />
                  </PrivateRoute>
              )}
              />
              <Route
                path="/admin-dashboard"
                element={(
                  <PrivateRoute user={user} handleSignOut={handleSignOut}>
                    <AdminDashboard user={user} />
                  </PrivateRoute>
              )}
              />
              <Route
                path="*"
                element={<NotFound isAuthenticated={user?.isAuthenticated} />}
              />
            </>
          </Routes>
        </PageTemplate>
      )}
    </Router>
  );

  const isDev = import.meta.env.VITE_SENTRY_ENV !== 'production'
    && import.meta.env.VITE_SENTRY_ENV !== 'staging';

  return isDev
    ? routes
    : (
      <Sentry.ErrorBoundary fallback={<Error />}>
        {routes}
      </Sentry.ErrorBoundary>
    );
}

App.defaultProps = {
  assumedUserId: null,
};

App.propTypes = {
  assumedUserId: PropTypes.string,
};

export default App;
