import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { config } from '~common/utils';
import { isCancelled } from '../../utils/helpers';
import useStyles from './styles';

const Appointment = ({
  children, style, data, userType, ...restProps
}) => {
  const { classes } = useStyles();
  const { UserType } = config;
  const {
    title, learner, tutor, status,
  } = data;
  const isParent = userType === UserType.PARENT;
  const isLearner = userType === UserType.LEARNER;

  const learnerName = (
    <Typography className={classes.text} component="div">
      {learner.fullName}
    </Typography>
  );

  const sessionTitle = (
    <Typography className={classes.text} component="div">
      <strong>{title}</strong>
    </Typography>
  );

  const tutorName = (
    <Typography className={classes.text} component="div">
      {tutor.fullName}
    </Typography>
  );

  let preview;

  if (isParent) {
    preview = (
      <>
        {sessionTitle}
        {learnerName}
      </>
    );
  } else if (isLearner) {
    preview = (
      <>
        {tutorName}
        {sessionTitle}
      </>
    );
  } else {
    preview = (
      <>
        {learnerName}
        {sessionTitle}
      </>
    );
  }

  return (
    <Appointments.Appointment
      {...restProps}
      data={data}
      style={{
        ...style,
        backgroundColor: data.color,
        textDecoration: isCancelled(status) ? 'line-through' : 'none',
        textDecorationColor: 'white',
      }}
    >
      <Appointments.Container>
        <div className={classes.container}>
          {preview}
        </div>
      </Appointments.Container>
    </Appointments.Appointment>
  );
};

Appointment.defaultProps = {
  data: {
    color: null,
  },
  style: null,
};

Appointment.propTypes = {
  data: PropTypes.shape({
    color: PropTypes.string,
    title: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    learner: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }),
    tutor: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }),
    status: PropTypes.string,
  }),
  userType: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

export default Appointment;
