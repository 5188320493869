import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  alert: {
    marginBottom: 10,
  },
  heading: {
    marginBottom: '30px',
  },
  paper: {
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  unverifiedRow: {
    background: 'rgb(253, 237, 237)',
  },
  headerBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    height: 40,
  },
  container: {
    padding: 17,
  },
});

export default useStyles;
