import PropTypes from 'prop-types';
import { Storage } from '@aws-amplify/storage';

import { FormTutorInfoFields } from '~common/components';

const AboutYouFields = ({
  control, getValues, watch, setValue, trigger, showFileUploadFields,
}) => (
  <FormTutorInfoFields
    control={control}
    getValues={getValues}
    trigger={trigger}
    watch={watch}
    setValue={setValue}
    Storage={Storage}
    showFileUploadFields={showFileUploadFields}
  />
);

AboutYouFields.defaultProps = {
  getValues: null,
  watch: null,
  setValue: null,
  trigger: null,
  showFileUploadFields: true,
};

AboutYouFields.propTypes = {
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  trigger: PropTypes.func,
  // TODO: Temporary disable file uploads on profile page until supported by backend.
  showFileUploadFields: PropTypes.bool,
};

export default AboutYouFields;
