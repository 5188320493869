import { useEffect } from 'react';
import dayjs from 'dayjs';
import { config } from '~common/utils';
import useTutorInvoicesStore, { tutorInvoiceSelector } from '../../../store/tutorInvoicesStore';
import useParentInvoicesStore, { parentInvoiceSelector } from '../../../store/parentInvoicesStore';

const useInvoices = ({ userType, paymentMonthId }) => {
  const { UserType } = config;
  let invoiceList = null;
  let totalEarned = '';
  let totalHoursTutored = '';
  let invoiceById = null;
  let pageTitle = 'Payments';
  const isParentUserType = userType === UserType.PARENT;
  const { invoices, getTutorInvoices } = useTutorInvoicesStore(tutorInvoiceSelector);
  const {
    invoices: parentInvoices, getParentInvoices,
  } = useParentInvoicesStore(parentInvoiceSelector);

  if (userType === UserType.TUTOR) {
    invoiceList = invoices?.invoices;
    totalEarned = invoices?.totalEarned;
    totalHoursTutored = invoices?.totalHoursTutored;
  }
  if (isParentUserType) {
    invoiceList = parentInvoices;
  }

  if (paymentMonthId) {
    invoiceById = invoiceList ? invoiceList.find((i) => i.id === paymentMonthId) : null;

    const titleDate = invoiceById?.createdAt ? ` - ${dayjs(invoiceById.createdAt).format('MMMM YYYY')}` : '';
    pageTitle = `Payments${titleDate}`;
  }

  useEffect(() => {
    if (userType === UserType.TUTOR) {
      getTutorInvoices();
    }
    if (isParentUserType) {
      getParentInvoices();
    }
  }, [getTutorInvoices, getParentInvoices]);

  return {
    invoiceList,
    totalEarned,
    totalHoursTutored,
    invoiceById,
    pageTitle,
    isParentUserType,
  };
};

export default useInvoices;
