import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Panel = ({ title, value }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.panel}>
      <div className={classes.valueContainer}>
        <div className={classes.value}>
          <Typography variant="h4" className={classes.valueText}>
            {value}
          </Typography>
        </div>
      </div>
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
};

Panel.defaultProps = {
  value: '0',
};

Panel.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
};

export default Panel;
