import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  message: {
    color: theme.palette.custom.secondary2,
    fontFamily: theme.typography.fontFamilyNunito,
    textAlign: 'center',
  },
  prefix: {
    marginTop: 16,
  },
  checkbox: {
    color: 'rgba(150, 150, 150, 0.6)',
    '&.Mui-checked': {
      color: '#162C5F',
    },
  },
  label: {
    color: theme.palette.custom.primary1,
    fontWeight: 500,
  },
}));

export default useStyles;
