/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';

export const getLearnersList = (learners) => (learners ? learners.map((learner) => ({
  ...learner,
  fullName: `${learner.firstName} ${learner.lastName}`,
})) : []);

export const getSubjectList = (subjects) => (subjects
  ? subjects
    .map((item) => item?.subject)
    .filter((subject, i, self) => self.findIndex(
      (e) => e.subjectName === subject.subjectName,
    ) === i)
  : []);

export const isFutureLesson = (startDate) => startDate > dayjs().valueOf();

const isWithinEditPeriod = (startDate) => startDate > dayjs().subtract(48, 'hours');

export const isCancelled = (status) => (status === 'CANCELLED_WITH_CHARGE' || status === 'CANCELLED_NO_CHARGE');

export const isEditable = ({ startDate, readOnly, isNewAppointment }) => {
  // Should not be editable for users without permission.
  if (readOnly) {
    return false;
  }

  // Should be editable if it is a new appointment and the user has permission.
  if (isNewAppointment) {
    return true;
  }

  return isWithinEditPeriod(startDate);
};

export const getFormTitle = ({
  startDate, readOnly, isNewAppointment, status,
}) => {
  if (isCancelled(status)) {
    return 'Viewing Cancelled Lesson';
  }

  if (!readOnly && isNewAppointment) {
    return 'Create Lesson';
  }

  if (!readOnly && !isNewAppointment && isFutureLesson(startDate)) {
    return 'Editing Upcoming Lesson';
  }

  if (!readOnly && !isNewAppointment && !isFutureLesson(startDate)) {
    return 'Editing Past Lesson';
  }

  return 'Lesson';
};

export const getDefaultLessonDuration = (appointmentData, lessonDurationOptions) => {
  const { lessonDuration, startDate, endDate } = appointmentData;
  if (startDate && endDate) {
    return lessonDurationOptions.find((x) => x.value === (endDate - startDate)) ?? null;
  }

  return lessonDuration;
};
