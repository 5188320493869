import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, props) => ({
  timeIndicatorCircle: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: props?.top || 0,
    width: 10,
    height: 10,
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.custom.accent5,
  },
  timeIndicatorline: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: props?.top || 0,
    height: '2px',
    borderTop: `2px ${theme.palette.custom.accent5} dotted`,
    width: '100%',
    transform: 'translate(0, -1px)',
  },
}));

export default useStyles;
