import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme, { color }) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    paddingLeft: theme.spacing(2),
  },
  dot: {
    maxWidth: '14px',
    maxHeight: '14px',
    marginRight: theme.spacing(1),
    color,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  fullname: {
    fontSize: '14px',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  learnerName: {
    fontSize: '14px',
    textOverflow: 'ellipsis',
    color: theme.palette.custom.secondary2,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  users: {
    paddingTop: theme.spacing(1),
  },
  nameText: {
    verticalAlign: 'text-bottom',
  },
}));
