import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  panel: {
    backgroundColor: '#FFFFFF',
    textAlign: 'center',
    padding: 27,
    minWidth: 220,
    borderRadius: 16,
  },
  title: {
    fontWeight: 700,
    color: theme.palette.custom.primary1,
    marginTop: 18,
  },
  valueContainer: {
    boxShadow: '0px 10px 50px rgba(182, 185, 199, 0.43)',
    borderRadius: '50%',
    height: 140,
    width: 140,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  value: {
    border: `1px solid ${theme.palette.custom.primary1}`,
    height: 113,
    width: 113,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  valueText: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.custom.primary1,
  },
}));

export default useStyles;
