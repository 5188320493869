import PropTypes from 'prop-types';
import {
  Grid, Typography, // Checkbox, FormGroup, FormControlLabel,
} from '@mui/material';

import {
  FormTextField,
  FormLearnerInfoFields,
  FormAutocomplete,
  FormRadioGroup,
} from '~common/components';
import { dataLists } from '~common/utils';
import useStyles from './styles';

const { countryCodeOptions } = dataLists;

const ParentLearnerFields = ({
  control, getValues, setValue, watch, type, trigger,
}) => {
  const { classes } = useStyles();
  const isNewLearner = type === 'parentLearnerCreate';

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="firstName"
          control={control}
          inputProps={{
            label: 'First Name',
            autoFocus: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="lastName"
          control={control}
          inputProps={{
            label: 'Last Name',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          name="emailAddress"
          control={control}
          inputProps={{
            label: 'Email Address',
          }}
          readOnly={!isNewLearner}
        />
        {!isNewLearner && (
          <Typography variant="body2" className={classes.message}>
            Please note: If you need to update your email address please contact an administrator.
          </Typography>
        )}
      </Grid>
      {isNewLearner && (
        <Grid item xs={12}>
          <FormRadioGroup
            name="ownAccount"
            control={control}
            label="Would you like the student to have access to their own account using their own log in details?"
          />
        </Grid>
      )}
      <Grid item xs={2}>
        <FormAutocomplete
          name="phoneNumberPrefix"
          control={control}
          className={classes.prefix}
          options={countryCodeOptions}
          disableClearable
          getOptionLabel={(option) => option?.value}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              ({option.value}) {option.name}
            </li>
          )}
          defaultValue={getValues('phoneNumberPrefix')}
          inputProps={{
            placeholder: '+44',
          }}
        />
      </Grid>
      <Grid item xs={10}>
        <FormTextField
          name="phoneNumber"
          control={control}
          inputProps={{
            label: 'Number',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLearnerInfoFields
          type={type}
          control={control}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          trigger={trigger}
        />
      </Grid>
    </Grid>
  );
};

ParentLearnerFields.defaultProps = {
  getValues: null,
  setValue: null,
  trigger: null,
};

ParentLearnerFields.propTypes = {
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  trigger: PropTypes.func,
  watch: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['parentLearnerCreate', 'parentLearnerEdit']).isRequired,
};

export default ParentLearnerFields;
