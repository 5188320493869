import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  Appointments,
  MonthView,
  DayView,
  Toolbar,
  ViewSwitcher,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
  AppointmentForm,
  CurrentTimeIndicator,
} from '@devexpress/dx-react-scheduler-material-ui';
import { Paper } from '@mui/material';
import dayjs from 'dayjs';

import { WeekViewTimeTableCell, MonthTimeTableCell, DayTimeTableCell } from './components/cells/timeTableCell';
import DayScaleCell from './components/cells/dayScaleCell';
import useStyles from './styles';
import { getYearDates } from '../../utils/calendarDays';
import Overlay from './components/overlay';
import CancelDialog from './components/cancelDialog';
import LayoutComponent from './components/layoutComponent';
import TimeIndicator from './components/cells/TimeIndicator';
import Appointment from './components/appointment';

const Calendar = ({
  data,
  readOnly,
  confirmationVisible,
  setConfirmationVisible,
  appointmentProps,
  onDateChange,
  dates,
  isMobileView,
}) => {
  const { classes } = useStyles();
  const [currentViewName, setCurrentViewName] = useState(isMobileView ? 'Day' : 'Week');
  const todaysDate = new Date();
  const [currentDate, setCurrentDate] = useState(todaysDate);
  const {
    commitChanges,
    setAppointmentFormVisible,
    commitCancelledAppointment,
    onEditingAppointmentChange,
    onAddedAppointmentChange,
    appointmentFormVisible,
    userType,
  } = appointmentProps;

  useEffect(() => {
    onDateChange(dates);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.querySelectorAll('#time-indicator')[0].scrollIntoView({ block: 'center', behavior: 'instant', scrollMode: 'if-needed' });
    }, 0);
  });

  // Control for View Switcher
  const currentViewNameChange = async (newcurrentViewName) => {
    setCurrentViewName(newcurrentViewName);
  };

  useEffect(() => {
    if (isMobileView) {
      currentViewNameChange('Day');
    } else {
      currentViewNameChange('Week');
    }
  }, [isMobileView]);

  // Control for Date Navigator
  const currentDateChange = async (newcurrentDate) => {
    setCurrentDate(newcurrentDate);

    // Make a new get sessions request if current date is more than 1 year in the future or past.
    if (dayjs(currentDate).isAfter(dayjs(dates?.endDate))) {
      onDateChange(getYearDates(currentDate));
    }
    if (dayjs(currentDate).isBefore(dayjs(dates?.startDate))) {
      onDateChange(getYearDates(currentDate));
    }
  };

  return (
    <Paper className={classes.container}>
      <Scheduler data={data} firstDayOfWeek={1}>
        <ViewState
          currentViewName={currentViewName}
          onCurrentViewNameChange={currentViewNameChange}
          currentDate={currentDate}
          onCurrentDateChange={currentDateChange}
        />
        <Toolbar />
        <DateNavigator />
        <TodayButton />
        <ViewSwitcher />
        <DayView
          startDayHour={0}
          endDayHour={24}
          cellDuration={60}
          timeTableCellComponent={(props) => DayTimeTableCell(props, readOnly, todaysDate)}
        />
        <WeekView
          startDayHour={0}
          endDayHour={24}
          timeTableCellComponent={(props) => WeekViewTimeTableCell(props, readOnly, todaysDate)}
          dayScaleCellComponent={DayScaleCell}
          cellDuration={60}
          readOnly={readOnly}
        />
        <MonthView
          timeTableCellComponent={(props) => MonthTimeTableCell(props, readOnly, todaysDate)}
        />
        <EditingState
          onCommitChanges={commitChanges}
          onEditingAppointmentChange={onEditingAppointmentChange}
          onAddedAppointmentChange={onAddedAppointmentChange}
        />
        <IntegratedEditing />
        <Appointments appointmentComponent={(props) => Appointment({ ...props, userType })} />
        <AppointmentTooltip
          layoutComponent={(props) => LayoutComponent({ ...props, userType, readOnly })}
        />
        <AppointmentForm
          overlayComponent={(props) => Overlay({ overlayProps: props, appointmentProps, readOnly })}
          visible={appointmentFormVisible}
          onVisibilityChange={setAppointmentFormVisible}
          readOnly={readOnly}
        />
        <CurrentTimeIndicator
          indicatorComponent={TimeIndicator}
          shadePreviousCells
          shadePreviousAppointments
        />
      </Scheduler>
      <CancelDialog
        confirmationVisible={confirmationVisible}
        setConfirmationVisible={setConfirmationVisible}
        commitCancelledAppointment={commitCancelledAppointment}
      />
    </Paper>
  );
};

Calendar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    id: PropTypes.string,
    subject: PropTypes.shape({}),
    learners: PropTypes.shape({}),
    allDay: PropTypes.bool,
    callDetails: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
  })).isRequired,
  onDateChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  appointmentProps: PropTypes.shape({
    setAppointmentFormVisible: PropTypes.func.isRequired,
    commitChanges: PropTypes.func.isRequired,
    commitCancelledAppointment: PropTypes.func.isRequired,
    onEditingAppointmentChange: PropTypes.func.isRequired,
    onAddedAppointmentChange: PropTypes.func.isRequired,
    appointmentFormVisible: PropTypes.bool.isRequired,
    userType: PropTypes.string.isRequired,
  }).isRequired,
  confirmationVisible: PropTypes.bool.isRequired,
  setConfirmationVisible: PropTypes.func.isRequired,
  dates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  isMobileView: PropTypes.bool.isRequired,
};

export default Calendar;
