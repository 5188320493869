import { useSessionsStore } from '~common/store';

export const sessionsSelector = (state) => ({
  sessions: state.sessions,
  upcomingSessions: state.upcomingSessions,
  getSessions: state.getSessions,
  addSession: state.addSession,
  updateSession: state.updateSession,
  cancelSession: state.cancelSession,
});

export default useSessionsStore;
