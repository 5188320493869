import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  container: {
    position: 'relative',
  },
  upload: {
    float: 'right',
    height: '215px',
    width: '300px',
    position: 'absolute',
    display: 'inline-block',
    justifyContent: 'center',
    alignItems: 'center',
    top: '-20px',
    right: '-50px',
    color: theme.palette.custom.primary2,
    padding: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingRight: 24,
    paddingBottom: 16,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.custom.primary,
    fontWeight: 'bold',
  },
  titleIcon: {
    marginLeft: 'auto',
  },
}));

export default useStyles;
