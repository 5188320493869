import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import {
  FormTextField,
  FormAddressFields,
  FormAutocomplete,
} from '~common/components';
import { dataLists, useAuth } from '~common/utils';
import { Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import useStyles from './styles';

const { countryCodeOptions } = dataLists;

const PersonalDetailsFields = ({
  control,
  getValues,
  showAddressFields,
  showPhoneNumber,
}) => {
  const { classes } = useStyles();
  const {
    user,
  } = useAuth({
    Hub,
    Auth,
    bypassCache: false,
  });
  return (
    <>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="firstName"
            control={control}
            inputProps={{
              label: 'First Name',
              autoFocus: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="lastName"
            control={control}
            inputProps={{
              label: 'Last Name',
            }}
          />
        </Grid>
        {showPhoneNumber && (
          <>
            <Grid item xs={2}>
              <FormAutocomplete
                name="phoneNumberPrefix"
                control={control}
                className={classes.prefix}
                options={countryCodeOptions}
                disableClearable
                getOptionLabel={(option) => option?.value}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    ({option.value}) {option.name}
                  </li>
                )}
                defaultValue={getValues('phoneNumberPrefix')}
                inputProps={{
                  placeholder: '+44',
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <FormTextField
                name="phoneNumber"
                control={control}
                inputProps={{
                  label: 'Number',
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      {showAddressFields && (
        <FormAddressFields
          setValue={(value) => {
            /* eslint-disable-next-line no-console */
            console.log(value);
          }}
          control={control}
          getValues={getValues}
          userType={user.userType}
        />
      )}
    </>
  );
};

PersonalDetailsFields.defaultProps = {
  getValues: null,
  showAddressFields: true,
  showPhoneNumber: false,
};

PersonalDetailsFields.propTypes = {
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func,
  showPhoneNumber: PropTypes.bool,
  showAddressFields: PropTypes.bool,
};

export default PersonalDetailsFields;
