import PropTypes from 'prop-types';
import {
  Typography,
} from '@mui/material';
import { AvatarImage } from '~common/components';
import useStyles from './styles';

const User = ({ user, userColorMap }) => {
  const {
    firstName, lastName, id,
  } = user;
  const userColor = userColorMap ? userColorMap?.[id] : null;
  const { classes } = useStyles({ userColor });

  return (
    <div className={classes.row}>
      <span className={classes.row}>
        <AvatarImage
          size="s"
          src={`${import.meta.env.VITE_AWS_AVATAR_URL}/${id}`}
          borderColor={userColor}
        />
        <Typography variant="body1" className={classes.text}>
          {`${firstName} ${lastName}`}
        </Typography>
      </span>
    </div>
  );
};

User.defaultProps = {
  userColorMap: null,
};

User.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    icon: PropTypes.string,
    userColor: PropTypes.string,
  }).isRequired,
  userColorMap: PropTypes.shape({}),
};
const UserList = ({
  userColorMap, userListType, users,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.subHeader}>
        <Typography variant="body1" className={classes.title}>
          {userListType}
        </Typography>
      </div>
      <div className={classes.container}>
        {users.length
          ? users.map((user) => (
            <User
              user={user}
              userColorMap={userColorMap}
              key={user.id}
            />
          ))
          : (
            <Typography variant="body2" className={classes.noDataText}>
              None allocated.
            </Typography>
          )}
      </div>
    </>
  );
};

UserList.defaultProps = {
  userColorMap: null,
  userListType: '',
  users: [],
};

UserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    icon: PropTypes.string,
    userColor: PropTypes.string,
  })),
  userColorMap: PropTypes.shape({}),
  userListType: PropTypes.string,
};

export default UserList;
