import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme, { color }) => ({
  textCenter: {
    textAlign: 'center',
    height: 20,
  },
  icon: {
    fill: '#64b5f6',
  },
  container: {
    padding: '0 16px 16px',
  },
  subject: {
    fontSize: '16px',
    fontWeight: '600',
    textOverflow: 'ellipsis',
    paddingTop: 6,
  },
  title: {
    fontSize: '14px',
    textOverflow: 'ellipsis',
  },
  date: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#969696',
  },
  time: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#969696',
  },
  dot: {
    display: 'inline-block',
    maxWidth: '14px',
    maxHeight: '14px',
    marginRight: theme.spacing(1),
    color,
  },
  fullName: {
    display: 'inline-block',
    fontSize: '14px',
    marginRight: 'auto',
    textOverflow: 'ellipsis',
  },
  nameRow: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  link: {
    fontSize: '14px',
    fontFamily: theme.typography.fontFamilyNunito,
    color: theme.palette.custom.primary1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
