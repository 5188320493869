import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, props) => ({
  container: {
    marginBottom: 12,
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  title: {
    color: theme.palette.custom.primary1,
    fontWeight: 700,
  },
  sessionsCard: {
    backgroundColor: theme.palette.custom.secondary5,
    padding: '12px 15px',
    borderRadius: 10,
    marginBottom: 10,
    border: `1px solid ${props?.color || theme.palette.custom.accent1}`,
  },
  circle: {
    height: 10,
    width: 10,
    marginRight: 10,
    color: theme.palette.custom.accent1,
  },
  dateText: {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: theme.typography.fontFamilyNunito,
    marginBottom: 4,
  },
  timeText: {
    fontSize: 10,
    fontFamily: theme.typography.fontFamilyNunito,
    marginBottom: 4,
  },
  callDetailsText: {
    fontFamily: theme.typography.fontFamilyNunito,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  cardInfo: {
    color: theme.palette.custom.secondary2,
  },
  nameText: {
    fontSize: 12,
    color: theme.palette.custom.secondary1,
    fontFamily: theme.typography.fontFamilyNunito,
    marginBottom: 8,
  },
  learnerNameText: {
    fontSize: 12,
    color: theme.palette.custom.secondary1,
    fontFamily: theme.typography.fontFamilyNunito,
  },
  subjectText: {
    color: theme.palette.custom.secondary1,
    fontWeight: 600,
    fontFamily: theme.typography.fontFamilyNunito,
  },
  icons: {
    display: 'flex',
  },
  editIcon: {
    color: theme.palette.custom.secondary2,
    height: 14,
    width: 14,
    marginLeft: 'auto',
  },
  clearIcon: {
    color: theme.palette.custom.secondary2,
    height: 14,
    width: 14,
    marginLeft: 8,
  },
  link: {
    left: 10,
    color: theme.palette.custom.secondary1,
    fontWeight: 500,
    fontSize: 14,
    textDecorationColor: theme.palette.custom.secondary1,
  },
  noDataText: {
    textAlign: 'center',
  },
}));

export default useStyles;
