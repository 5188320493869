import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  cardList: {
    width: '100%',
  },
  cardArea: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    marginBottom: 10,
    color: theme.palette.custom.primary1,
    fontFamily: theme.typography.fontFamilyNunito,
    boxShadow: 'none',
    padding: '16px 52px 16px 36px',
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  attendeeInfo: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  profileIcon: {
    color: theme.palette.custom.secondary3,
    borderRadius: '50%',
    boxShadow: '0px 10px 50px #B6B9C7',
    marginRight: 8,
  },
  calendarIcon: {
    marginRight: 12,
    color: theme.palette.custom.primary1,
  },
  statusText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardText: {
    fontFamily: theme.typography.fontFamilyNunito,
  },
  message: {
    fontFamily: theme.typography.fontFamilyNunito,
    textAlign: 'center',
  },
  description: {
    fontFamily: theme.typography.fontFamilyNunito,
  },
  link: {
    color: theme.palette.custom.primary2,
    textDecoration: 'underline',
    textDecorationColor: theme.palette.custom.primary2,
  },
  date: {
    display: 'flex',
  },
  cardTitle: {
    fontWeight: 700,
    color: theme.palette.custom.primary1,
    fontFamily: theme.typography.fontFamilyNunito,
  },
  summaryTitleBar: {
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    marginBottom: 10,
    padding: '16px 52px 16px 36px',
    height: 54,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  noDataText: {
    textAlign: 'center',
    padding: 24,
    backgroundColor: '#FFFFFF',
  },
}));

export default useStyles;
