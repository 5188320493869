import PropTypes from 'prop-types';
import { Box, Snackbar, Slide } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Button } from '~common/components';
import useCalendarFeeds from './hooks/useCalendarFeeds';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  snackbar: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: theme.palette.custom.primary1,
      color: 'white',
    },
  },
  button: {
    margin: theme.spacing(0.5),
  },
}));

const CalendarFeeds = ({ user }) => {
  const { classes } = useStyles();
  const { calendarFeeds, copyResult, setCopyResult } = useCalendarFeeds({ user });

  return calendarFeeds.length > 0 && user
    ? (
      <Box className={classes.root}>
        <Snackbar
          className={classes.snackbar}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={!!copyResult}
          message={copyResult}
          autoHideDuration={1500}
          TransitionComponent={Slide}
          onClose={() => {
            setCopyResult(null);
          }}
        />
        <Box className={classes.container}>
          {calendarFeeds.map((feed) => (
            <div key={feed.key}>
              <Button
                className={classes.button}
                variant="text"
                endIcon={<ContentCopyIcon />}
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(feed.calendarUrl);
                    setCopyResult(`${feed.label} was successfully copied to the clipboard`);
                  } catch (error) {
                    setCopyResult('Unable to copy the calendar feed');
                  }
                }}
              >
                {feed.label}
              </Button>
            </div>
          ))}
        </Box>
      </Box>
    )
    : null;
};

CalendarFeeds.defaultProps = {
  user: undefined,
};

CalendarFeeds.propTypes = {
  user: PropTypes.shape({}),
};
export default CalendarFeeds;
