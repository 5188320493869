import PropTypes from 'prop-types';
import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';
import IconButton from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { config } from '~common/utils';
import useStyles from './styles';
import { isEditable } from '../../../../utils/helpers';

const HeaderComponent = ({
  children, readOnly, userType, appointmentData, ...otherProps
}) => {
  const { UserType } = config;
  const {
    startDate,
    tutor,
    learner,
    color,
  } = appointmentData;
  const { classes } = useStyles({ color });
  const canEditLesson = isEditable({ startDate, readOnly, isNewAppointment: false });
  const isTutor = userType === UserType.TUTOR;
  const isParent = userType === UserType.PARENT;

  return (
    <div className={classes.header}>
      <Grid container className={classes.users}>
        <Grid item xs={12}>
          <Typography
            className={classes.fullname}
            variant="body2"
            component="div"
          >
            <CircleIcon className={classes.dot} />
            <span className={classes.nameText}>{isTutor ? learner.fullName : tutor.fullName}</span>
          </Typography>
        </Grid>
        {isParent
          && (
          <Grid item xs={12}>
            <Typography
              className={classes.learnerName}
              variant="body2"
              component="div"
            >
              {learner.fullName}
            </Typography>
          </Grid>
          )}
      </Grid>
      <AppointmentTooltip.Header
        {...otherProps}
        appointmentData={appointmentData}
        showCancelButton={canEditLesson}
        showOpenButton={isTutor}
        showCloseButton
      >
        {!isTutor
          && (
            <IconButton
              onClick={() => {
                otherProps.onHide();
                otherProps.onOpenButtonClick();
              }}
            >
              <InfoIcon fontSize="large" />
            </IconButton>
          )}
      </AppointmentTooltip.Header>
    </div>
  );
};

HeaderComponent.propTypes = {
  appointmentData: PropTypes.shape({
    title: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    id: PropTypes.string,
    subject: PropTypes.shape({
      subjectName: PropTypes.string.isRequired,
    }),
    learner: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }),
    tutor: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }),
    allDay: PropTypes.bool,
    callDetails: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
};

export default HeaderComponent;
