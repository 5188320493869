import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '~common/components';
import { config } from '~common/utils';
import PageTitle from '../../components/pageTitle';
import useStyles from './styles';
import PaymentsSummary from './components/paymentsSummary';
import PaymentsMonthly from './components/paymentsMonthly';
import useInvoices from './hooks/useInvoices';

const Payments = ({ userData }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { UserType } = config;
  const { userType } = userData;
  const { paymentMonthId } = useParams();

  if (userType === UserType.LEARNER) {
    navigate('/');
  }

  const {
    invoiceList,
    totalEarned,
    totalHoursTutored,
    invoiceById,
    pageTitle,
    isParentUserType,
  } = useInvoices({ userType, paymentMonthId });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <PageTitle title={pageTitle} />
        {paymentMonthId && (
        <Button
          variant="text"
          className={classes.button}
          onClick={() => navigate('/payments')}
          disableRipple
        >
          Payment History
        </Button>
        )}
      </div>
      {paymentMonthId
        ? (
          <PaymentsMonthly invoice={invoiceById} isParentUserType={isParentUserType} />
        )
        : (
          <PaymentsSummary
            invoices={invoiceList}
            totalEarned={totalEarned}
            totalHoursTutored={totalHoursTutored}
            isParentUserType={isParentUserType}
          />
        )}
    </div>
  );
};

Payments.propTypes = {
  userData: PropTypes.shape({
    userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
  }).isRequired,
};

export default Payments;
