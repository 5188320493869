import PropTypes from 'prop-types';
import { AppointmentForm } from '@devexpress/dx-react-scheduler-material-ui';

import useStyles from './styles';
import LessonForm from '../lessonForm';

const Overlay = ({ overlayProps, appointmentProps, readOnly }) => {
  const { classes } = useStyles();

  return (
    <AppointmentForm.Overlay {...overlayProps} className={classes.overlay}>
      <LessonForm appointmentProps={appointmentProps} readOnly={readOnly} />
    </AppointmentForm.Overlay>
  );
};

Overlay.defaultProps = {
  readOnly: false,
};

Overlay.propTypes = {
  overlayProps: PropTypes.shape({}).isRequired,
  readOnly: PropTypes.bool,
  appointmentProps: PropTypes.shape({
    appointmentData: PropTypes.shape({
      title: PropTypes.string,
      startDate: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.instanceOf(Date),
      ]),
      endDate: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.instanceOf(Date),
      ]),
      id: PropTypes.string,
      subject: PropTypes.shape({}),
      learners: PropTypes.shape({}),
      allDay: PropTypes.bool,
      callDetails: PropTypes.string,
      description: PropTypes.string,
    }),
    learnersList: PropTypes.arrayOf(PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      id: PropTypes.string,
      emailAddress: PropTypes.string,
      fullName: PropTypes.string,
    })),
    subjectList: PropTypes.arrayOf(PropTypes.shape({
      subjectName: PropTypes.string,
      id: PropTypes.number,
    })),
    commitChanges: PropTypes.func.isRequired,
    setAppointmentFormVisible: PropTypes.func.isRequired,
    isNewAppointment: PropTypes.bool.isRequired,
    cancelChanges: PropTypes.func.isRequired,
  }).isRequired,
};

export default Overlay;
