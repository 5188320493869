import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    height: 90,
    marginBottom: 20,
  },
  box: {
    background: `linear-gradient(90deg, #1D2F5A 0.13%, ${theme.palette.custom.primary2} 58.31%, #384B77 99.87%)`,
    borderRadius: 16,
    color: '#F9F9F9',
    height: 70,
    padding: '20px 48px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
  },
  nameTitle: {
    fontWeight: 700,
    fontSize: 20,
    display: 'inline',
  },
  text: {
    marginTop: 14,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    paddingRight: 20,
    paddingTop: 6,
    '& img': {
      width: 100,
      height: 80,
    },
  },
}));

export default useStyles;
