import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Alert, Grid, Modal, Typography,
} from '@mui/material';

import { Button } from '~common/components';
import useStyles from './styles';
import useLessonData from './hooks/useLessonData';
import LessonForm from '../../components/calendar/components/lessonForm';
import lessonsConfig from './lessonsConfig';
import CancelDialog from '../../components/calendar/components/cancelDialog';
import InfoCard from '../../components/infoCard';
import PageTitle from '../../components/pageTitle';
import CreateLessonButton from '../../components/calendar/components/createLessonButton';
import defaultSessionData from '../../utils/sessionForm.json';

const Lessons = ({ userData }) => {
  // Ensure we clear location state on refresh.
  window.history.replaceState({}, document.title);

  const { classes } = useStyles();
  const { userType } = userData;
  const { state } = useLocation();
  const createLesson = state?.createLesson;
  const { canCreateLesson } = lessonsConfig[userType];
  const {
    data,
    error,
    success,
    confirmationVisible,
    setConfirmationVisible,
    handleGetSessions,
    futureLessonDates,
    handleShowLessonsToggle,
    showPastLessons,
    appointmentProps,
  } = useLessonData({ userData });
  const {
    onEditingAppointmentChange,
    onAddedAppointmentChange,
    setAppointmentFormVisible,
    appointmentFormVisible,
    commitCancelledAppointment,
  } = appointmentProps;

  useEffect(() => {
    handleGetSessions(futureLessonDates);
  }, []);

  // Use location state to determine if we should open the create lesson modal.
  // The state is set from the create lesson button on the nav bar for mobile.
  useEffect(() => {
    if (createLesson) {
      setAppointmentFormVisible(true);
      onEditingAppointmentChange(undefined);
      onAddedAppointmentChange(defaultSessionData);
    }
  }, [state]);

  return (
    <div className={classes.container}>
      <div>
        <Grid container className={classes.header}>
          <Grid item xs={12} md={4}>
            <PageTitle title={showPastLessons ? 'All Past Lessons' : 'All Upcoming Lessons'} />
          </Grid>
          <Grid item xs={12} md={8}>
            <div className={classes.controlButtons}>
              <Button
                onClick={handleShowLessonsToggle}
                color="secondary"
                className={classes.toggleButtonHeader}
              >
                {showPastLessons ? 'Show Upcoming Lessons' : 'Show Past Lessons'}
              </Button>
              {canCreateLesson && (
              <CreateLessonButton
                setAppointmentFormVisible={setAppointmentFormVisible}
                onEditingAppointmentChange={onEditingAppointmentChange}
                onAddedAppointmentChange={onAddedAppointmentChange}
              />
              )}
            </div>
          </Grid>
        </Grid>
        {error && <Alert severity="error" className={classes.alert}>{error}</Alert>}
        {success && <Alert severity="success" className={classes.alert}>{success}</Alert>}
        {data?.length
          ? (
            <div className={classes.cardList}>
              {data.map((session) => (
                <InfoCard
                  session={session}
                  userType={userType}
                  key={session?.id}
                  setAppointmentFormVisible={setAppointmentFormVisible}
                  onEditingAppointmentChange={onEditingAppointmentChange}
                  showPastLessons={showPastLessons}
                />
              ))}
            </div>
          ) : (
            <Typography variant="body1" className={classes.noDataText}>
              No lesson data.
            </Typography>
          )}
      </div>
      <div className={classes.controlButtonsSticky}>
        <Button
          onClick={handleShowLessonsToggle}
          color="secondary"
        >
          {showPastLessons ? 'Show Upcoming Lessons' : 'Show Past Lessons'}
        </Button>
      </div>
      <Modal
        open={appointmentFormVisible}
        onClose={() => setAppointmentFormVisible(false)}
        className={classes.modal}
      >
        <div className={classes.modalContainer}>
          <LessonForm
            readOnly={!canCreateLesson}
            appointmentProps={appointmentProps}
          />
        </div>
      </Modal>
      <CancelDialog
        confirmationVisible={confirmationVisible}
        setConfirmationVisible={setConfirmationVisible}
        commitCancelledAppointment={commitCancelledAppointment}
      />
    </div>
  );
};

Lessons.propTypes = {
  userData: PropTypes.shape({
    userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
  }).isRequired,
};

export default Lessons;
