import PropTypes from 'prop-types';
import { Button } from '~common/components';
import defaultSessionData from '../../../../utils/sessionForm.json';

const CreateLessonButton = ({
  setAppointmentFormVisible,
  onEditingAppointmentChange,
  onAddedAppointmentChange,
  ...otherProps
}) => (
  <Button
    onClick={() => {
      setAppointmentFormVisible(true);
      onEditingAppointmentChange(undefined);
      onAddedAppointmentChange(defaultSessionData);
    }}
    {...otherProps}
  >
    Create New Lesson
  </Button>
);

CreateLessonButton.propTypes = {
  onEditingAppointmentChange: PropTypes.func.isRequired,
  onAddedAppointmentChange: PropTypes.func.isRequired,
  setAppointmentFormVisible: PropTypes.func.isRequired,
};

export default CreateLessonButton;
