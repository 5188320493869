/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';

const getYearDates = (date) => {
  const pastDate = dayjs(date).subtract(1, 'year').format();
  const futureDate = dayjs(date).add(1, 'year').format();

  return {
    startDate: pastDate,
    endDate: futureDate,
  };
};

// Returns the start and end day of the month for a given date with a buffer of 7 days.
// The buffer is added because in the calendar view, days from the start and end of next and
// previous months are often displayed.
const getMonthDays = (date) => {
  const startDate = dayjs(date).startOf('month');
  const endDate = dayjs(date).endOf('month');

  const startDateBuffer = startDate.subtract(7, 'day').format();
  const endDateBuffer = endDate.add(7, 'day').format();

  return {
    startDate: startDateBuffer,
    endDate: endDateBuffer,
  };
};

// Returns the start and end day of the week for a given date.
const getWeekDays = (date) => {
  const startDate = dayjs(date).startOf('week').format();
  const endDate = dayjs(date).endOf('week').format();

  return {
    startDate,
    endDate,
  };
};

const getDay = (date) => {
  const startDate = dayjs(date).startOf('day').format();
  const endDate = dayjs(date).endOf('day').format();

  return {
    startDate,
    endDate,
  };
};

export {
  getYearDates,
  getMonthDays,
  getWeekDays,
  getDay,
};
