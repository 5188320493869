import { useUserStore } from '~common/store';

export const userSelector = (state) => ({
  assumedUserId: state.assumedUserId,
  isAssumedMode: state.isAssumedMode,
  setAssumedUserId: state.setAssumedUserId,
  user: state.user,
  getUser: state.getUser,
  updateUser: state.updateUser,
  error: state.error,
  createLearner: state.createLearner,
});

export default useUserStore;
