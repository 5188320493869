import { useCallback, useEffect, useState } from 'react';
import useUserStore, { userSelector } from '../../store/userStore';

const useUser = ({ assumedUserId = null } = {}) => {
  const {
    user,
    getUser,
    setAssumedUserId,
    isAssumedMode,
  } = useUserStore(userSelector);
  const [loading, setLoading] = useState(true);

  const handleSetAssumedUserId = useCallback(
    async (id) => (id ? setAssumedUserId(id) : null),
    [setAssumedUserId],
  );

  const handleGetUser = useCallback(
    async () => getUser(),
    [getUser],
  );

  useEffect(() => {
    const init = async () => {
      await handleSetAssumedUserId(assumedUserId);
      await handleGetUser();
      setLoading(false);
    };

    init();
  }, [handleGetUser]);

  return {
    isAssumedMode,
    userData: user,
    loading,
  };
};

export default useUser;
