import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import dayjs from 'dayjs';

import { Button } from '~common/components';
import { config } from '~common/utils';
import useStyles from './styles';

const SessionsCard = ({
  session, setAppointmentFormVisible, onEditingAppointmentChange, userType,
}) => {
  const { UserType } = config;
  const {
    title, startDate, endDate, color, callDetails, subject: { subjectName },
  } = session;
  const { classes } = useStyles({ color });
  const isParent = userType === UserType.PARENT;
  const learner = session.learner?.fullName;
  const attendeeName = userType === UserType.TUTOR
    ? learner : session.tutor?.fullName;

  const time = startDate && endDate
  && `${dayjs(startDate).format('h:mm')} - ${dayjs(endDate).format('h:mm')}`;
  const date = startDate && dayjs(startDate).format('dddd, D MMMM');

  return (
    <Grid container className={classes.sessionsCard}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.nameText}>
              <CircleIcon className={classes.circle} />{attendeeName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.icons}>
              <IconButton
                className={classes.editIcon}
                onClick={() => {
                  setAppointmentFormVisible(true);
                  onEditingAppointmentChange(session);
                }}
              >
                {userType === 'tutor' ? <EditIcon /> : <InfoIcon />}
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.cardInfo}>
        <Typography variant="body2" className={classes.dateText}>
          {date} <span className={classes.timeText}>{time}</span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.subjectText}>
          <span>{subjectName}:</span> <span>{title}</span>
        </Typography>
        {isParent
          && (
          <Typography variant="body2" className={classes.learnerNameText}>
            {learner}
          </Typography>
          )}
        {callDetails && (
          <Typography variant="body2" className={classes.callDetailsText}>
            <a href={callDetails} target="_blank" rel="noreferrer">{callDetails}</a>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

SessionsCard.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    learner: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    tutor: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    color: PropTypes.string,
    callDetails: PropTypes.string,
    subject: {
      subjectName: PropTypes.string,
    },
  }).isRequired,
  setAppointmentFormVisible: PropTypes.func.isRequired,
  onEditingAppointmentChange: PropTypes.func.isRequired,
  userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
};

const UpcomingSessions = ({
  data, setAppointmentFormVisible, onEditingAppointmentChange, userType,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.subHeader}>
        <Typography variant="body1" className={classes.title}>
          Upcoming sessions
        </Typography>
        <Button
          className={classes.link}
          variant="text"
          onClick={() => navigate('/lessons')}
        >
          See all
        </Button>
      </div>
      {data.length
        ? data.map((session) => (
          <SessionsCard
            session={session}
            setAppointmentFormVisible={setAppointmentFormVisible}
            onEditingAppointmentChange={onEditingAppointmentChange}
            key={session.id}
            userType={userType}
          />
        )) : (
          <Typography variant="body2" className={classes.noDataText}>
            No upcoming sessions
          </Typography>
        )}
    </div>
  );
};

UpcomingSessions.defaultProps = {
  data: [],
};

UpcomingSessions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    learner: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    tutor: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    color: PropTypes.string,
    callDetails: PropTypes.string,
  })),
  setAppointmentFormVisible: PropTypes.func.isRequired,
  onEditingAppointmentChange: PropTypes.func.isRequired,
  userType: PropTypes.oneOf(['admin', 'tutor', 'parent', 'learner']).isRequired,
};

export default UpcomingSessions;
